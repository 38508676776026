import React from "react";
import {
  IonRow,
  IonCol,
  IonSkeletonText,
  IonCard,
  IonCardContent,
} from "@ionic/react";
import IconList from "../../components/IconList";

const JOBOPENINGS = [
  {
    id: "h1",
    title: "Job Opening #1",
    subTitle: "Subtitle text",
    icon: "user-hard-hat",
    color: "secondary",
  },
  {
    id: "h2",
    title: "Job Opening #2",
    subTitle: "Subtitle text",
    icon: "user-hard-hat",
    color: "secondary",
  },
  {
    id: "h3",
    title: "Job Opening #3",
    subTitle: "Subtitle text",
    icon: "user-hard-hat",
    color: "secondary",
  },
];

const RESOURCES = [
  {
    id: "r1",
    title: "Apprenticeship Resources",
    subTitle: "Subtitle text",
    icon: "user-friends",
    color: "secondary",
  },
  {
    id: "r2",
    title: "Training Resources",
    subTitle: "Subtitle text",
    icon: "chalkboard-teacher",
    color: "secondary",
  },
  {
    id: "r3",
    title: "Employability Tips",
    subTitle: "Subtitle text",
    icon: "chalkboard-teacher",
    color: "secondary",
  },
  {
    id: "r4",
    title: "Ariel Business Group",
    subTitle: "Subtitle text",
    icon: "external-link-alt",
    color: "secondary",
  },
];

const EVENTS = [
  {
    id: "e1",
    title: "Job Fair Event",
    subTitle: "MM/DD/YYYY - 6:00pm",
    icon: "calendar-day",
    color: "secondary",
  },
  {
    id: "e2",
    title: "Outreach Event",
    subTitle: "MM/DD/YYYY - 7:00pm",
    icon: "calendar-day",
    color: "secondary",
  },
  {
    id: "e3",
    title: "Job Fair Event",
    subTitle: "MM/DD/YYYY - 3:00pm",
    icon: "calendar-day",
    color: "secondary",
  },
];

const WmbeSlbeLoading = () => {
  return (
    <>
      <IonRow>
        <IonCol size="12" sizeLg="12" sizeXl="10" offsetXl="1">
          <IonRow>
            <IonCol>
              <h2
                className="home"
                style={{
                  textAlign: "center",
                  marginBottom: "15px",
                }}
              >
                <IonSkeletonText
                  animated
                  style={{
                    width: "250px",
                    height: "30px",
                    margin: "0 auto",
                  }}
                />
              </h2>
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "var(--ion-color-primary)",
                }}
              >
                <IonSkeletonText
                  animated
                  style={{
                    width: "200px",
                    height: "30px",
                    margin: "0 auto",
                  }}
                />
              </span>
            </IonCol>
          </IonRow>
          <IonRow style={{ marginBottom: "20px" }}>
            <IonCol size="12" sizeSm="12" sizeMd="6" sizeXl="6">
              <IonCard>
                <IonCardContent>
                  <IconList
                    title="Westra Construction"
                    icon="westra"
                    data={JOBOPENINGS}
                    loading={true}
                  />
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size="12" sizeSm="12" sizeMd="6" sizeXl="6">
              <IonCard>
                <IonCardContent>
                  <IconList
                    title="Subcontractor "
                    icon="user-hard-hat"
                    iconColor="primary"
                    data={JOBOPENINGS}
                    loading={true}
                  />
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <h2
                className="home"
                style={{
                  textAlign: "center",
                  marginBottom: "5px",
                }}
              >
                <IonSkeletonText
                  animated
                  style={{
                    width: "300px",
                    height: "30px",
                    margin: "0 auto",
                  }}
                />
              </h2>
            </IonCol>
          </IonRow>
          <IonRow style={{ marginBottom: "20px" }}>
            <IonCol size="12" sizeSm="12" sizeMd="" sizeXl="6">
              <IonCard>
                <IonCardContent>
                  <IconList
                    title="Resources"
                    icon="briefcase"
                    iconColor="primary"
                    data={RESOURCES}
                    loading={true}
                  />
                </IonCardContent>
              </IonCard>
            </IonCol>

            <IonCol size="12" sizeSm="12" sizeMd="6" sizeXl="6">
              <IonCard>
                <IonCardContent>
                  <IconList
                    title="Upcoming Events"
                    icon="calendar-star"
                    iconColor="primary"
                    data={EVENTS}
                    loading={true}
                  />
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  );
};

export default WmbeSlbeLoading;
