import { IonLabel, IonItem, IonList, IonSkeletonText } from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logEvent } from "firebase/analytics";
import { analytics } from "..";

const IconList = (props) => {
  const { title, icon, iconColor, data, detailText, listOnly, loading } = props;

  const handleResourceClick = (d) => {
    logEvent(analytics, "view_resource", { resource: d.title, link: d.link });
  };
  return (
    <div className="hb-list">
      {!listOnly && (
        <>
          <div style={{ display: "block", textAlign: "center" }}>
            {icon === "westra" ? (
              <>
                {!loading ? (
                  <img
                    src="assets/img/westra-logo-outlined.png"
                    alt="Westra Contruction logo"
                    width="400"
                    height="400"
                    style={{
                      maxWidth: "78px",
                      height: "auto",
                      marginBottom: "14px",
                    }}
                  />
                ) : (
                  <IonSkeletonText
                    animated
                    style={{
                      width: "78px",
                      height: "76px",
                      margin: "0 auto",
                      marginBottom: "14px",
                      borderRadius: "10px",
                    }}
                  />
                )}
              </>
            ) : (
              <>
                {!loading ? (
                  <FontAwesomeIcon
                    icon={["fad", `${icon}`]}
                    style={{
                      fontSize: "55px",
                      marginBottom: "15px",
                      marginTop: "20px",
                    }}
                    color={`var(--ion-color-${
                      iconColor ? iconColor : "secondary"
                    })`}
                  />
                ) : (
                  <IonSkeletonText
                    animated
                    style={{
                      width: "78px",
                      height: "76px",
                      margin: "0 auto",
                      marginBottom: "14px",
                      borderRadius: "10px",
                    }}
                  />
                )}
              </>
            )}
          </div>
          <h3
            style={{
              textAlign: "center",
              fontSize: "28px",
              marginBottom: "10px",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {!loading ? (
              title
            ) : (
              <IonSkeletonText
                animated
                style={{
                  width: "300px",
                  height: "30px",
                  margin: "0 auto",
                }}
              />
            )}
          </h3>
          <div className="dotted-divider">
            <span></span>
          </div>
        </>
      )}

      <IonList className="resource-list" style={{ background: "none" }}>
        {data.map((d) => (
          <IonItem
            lines="none"
            className="list-item"
            key={d.id}
            detail={d.link ? true : false}
            href={d.link && d.link}
            target="_blank"
            onClick={() => handleResourceClick(d)}
          >
            <div slot="start">
              {!loading ? (
                <FontAwesomeIcon
                  color={`var(--ion-color-${d.color ? d.color : "secondary"})`}
                  icon={["fas", `${d.icon}`]}
                  style={{
                    fontSize: "30px",
                    minWidth: "30px",
                  }}
                />
              ) : (
                <IonSkeletonText
                  animated
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "0 auto",
                  }}
                />
              )}
            </div>
            <IonLabel className="hb-label">
              <span>
                {!loading ? (
                  d.title
                ) : (
                  <IonSkeletonText
                    animated
                    style={{
                      width: "170px",
                      height: "1.1rem",
                      borderRadius: "0px",
                    }}
                  />
                )}
              </span>

              {d.subTitle && (
                <h3>
                  {!loading ? (
                    d.subTitle
                  ) : (
                    <IonSkeletonText
                      animated
                      style={{
                        width: "100px",
                        height: "14px",
                        borderRadius: "0px",
                      }}
                    />
                  )}
                </h3>
              )}
            </IonLabel>
            <span slot="end" className="detailText">
              {detailText}
            </span>
          </IonItem>
        ))}
      </IonList>
    </div>
  );
};

export default IconList;
