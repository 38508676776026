import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import { IonCard, IonCardContent, IonCol, IonRow } from "@ionic/react";

function createHtml(html) {
  return {
    __html: html,
  };
}

const FAQs = ({ FAQS }) => {
  return (
    <>
      <IonRow style={{ marginBottom: "20px" }}>
        <IonCol size="12" sizeXl="10" offsetXl="1">
          <IonRow>
            <IonCol>
              <h2
                className="home"
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                Frequently Asked <strong>Questions</strong>
              </h2>
              <IonCard className="card-margin">
                <IonCardContent>
                  <div className="accordion" id="accordionExample">
                    {FAQS.map((faq) => (
                      <div className="accordion-item" key={faq.id}>
                        <h2
                          className="accordion-header"
                          id={`heading${faq.id}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${faq.id}`}
                            aria-expanded="false"
                            aria-controls={`collapse${faq.id}`}
                          >
                            {faq.question}
                          </button>
                        </h2>
                        <div
                          id={`collapse${faq.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`#heading${faq.id}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            dangerouslySetInnerHTML={createHtml(faq.answer)}
                          ></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  );
};

export default FAQs;
