import React from "react";
import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import AccessibilityModal from "./AccessibilityModal";
import PrivacyModal from "./PrivacyModal";
import {
  logoFacebook,
  logoInstagram,
  logoTwitter,
  logoYoutube,
} from "ionicons/icons";

const Footer = () => {
  return (
    <div className="custom-footer">
      <IonGrid>
        <IonRow
          className="ion-align-items-center"
          style={{ padding: "0px 10px" }}
        >
          <IonCol
            size="12"
            sizeMd="4"
            sizeLg="4"
            sizeSm="4"
            offsetLg="0"
            sizeXl="4"
            offsetXl="0"
            className="ion-text-center"
          >
            <span style={{ fontSize: "13px", fontWeight: "400" }}>
              © Copyright 2021 City of Tampa
            </span>
          </IonCol>
          <IonCol
            size="12"
            sizeMd="4"
            sizeSm="4"
            sizeLg="4"
            offsetLg="0"
            sizeXl="4"
            offsetXl="0"
            className="ion-text-center footer-center"
          >
            <AccessibilityModal />
            <PrivacyModal />
          </IonCol>
          <IonCol
            size="12"
            sizeLg="4"
            sizeMd="4"
            sizeSm="4"
            offsetLg="0"
            sizeXl="4"
            offsetXl="0"
            className="ion-text-center"
          >
            <IonCol>
              <a
                href="https://www.facebook.com/CityofTampaFL/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IonIcon
                  icon={logoFacebook}
                  style={{
                    fontSize: "20px",
                    color: "var(--ion-color-dark)",
                  }}
                />
              </a>
            </IonCol>
            <IonCol>
              <a
                href="https://twitter.com/cityoftampa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IonIcon
                  icon={logoTwitter}
                  style={{
                    fontSize: "20px",
                    color: "var(--ion-color-dark)",
                  }}
                />
              </a>
            </IonCol>

            <IonCol>
              <a
                href="https://www.instagram.com/cityoftampa/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IonIcon
                  icon={logoInstagram}
                  style={{
                    fontSize: "20px",
                    color: "var(--ion-color-dark)",
                  }}
                />
              </a>
            </IonCol>
            <IonCol>
              <a
                href="https://www.youtube.com/user/CityofTampa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IonIcon
                  icon={logoYoutube}
                  style={{
                    fontSize: "20px",
                    color: "var(--ion-color-dark)",
                  }}
                />
              </a>
            </IonCol>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Footer;
