/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { IonApp, setupConfig } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import { loadConfData } from "./data/projects/projects.actions";
import { AppContextProvider } from "./data/AppContext";
import { connect } from "./data/connect";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./scss/main.scss";

/* Font Awesome Icons */
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import Appp from "./Appp";
library.add(fas, far, fal, fad);

setupConfig({
  mode: "ios",
  swipeBackEnabled: false,
});

const App = () => {
  return (
    <AppContextProvider>
      <IonicAppConnected />
    </AppContextProvider>
  );
};

const IonicApp = ({ loadConfData, loading }) => {
  useEffect(() => {
    // Load App Data
    loadConfData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <Auth0ProviderWithHistory>
          <Appp />
        </Auth0ProviderWithHistory>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;

const IonicAppConnected = connect({
  mapStateToProps: (state) => ({
    loading: state.data.loading,
  }),
  mapDispatchToProps: {
    loadConfData,
  },
  component: IonicApp,
});
