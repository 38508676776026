import React from "react";
import { IonRow, IonCol } from "@ionic/react";
import "./Workforce.scss";

const AboutWestra = () => {
  return (
    <IonRow style={{ marginBottom: "0px" }}>
      <IonCol size="12" sizeXl="10" offsetXl="1">
        <IonRow>
          <IonCol>
            <h2
              className="home"
              style={{
                marginTop: "10px",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              About <strong>Westra</strong>
            </h2>

            <p>
              <img
                className="img-right"
                src="https://tampawaterfy20cip.com/wp-content/uploads/2021/03/westra-logo-outlined-300x292.png"
                alt="Westra Contruction logo"
                width="300"
                height="292"
              />
            </p>
            <p
              style={{
                margin: "15px auto 0px",
                maxWidth: "1250px",
                textAlign: "left",
                padding: "0px 20px",
                fontSize: "18px",
                color: "#000",
              }}
            >
              Since 1977, Westra Construction Corp. has been building a
              reputation for excellence. Our experienced staff, management
              teams, and state-of-the-art equipment give Westra the edge in
              handling a wide range of heavy construction projects. We
              specialize our work in the areas of water and reclaimed water
              distribution systems, sewer collection systems, force mains,
              stormwater collection systems, vacuum sewer systems, complete site
              work for private developments, roadway construction, heavy highway
              / D.O.T projects, Design-Build projects, and water/wastewater
              treatment plant facilities.
            </p>
            <br />
            <p
              style={{
                margin: "0px auto 15px",
                maxWidth: "1250px",
                textAlign: "left",
                padding: "0px 20px",
                fontSize: "18px",
                color: "#000",
              }}
            >
              Westra Construction Corp. is family-owned and state-certified as a
              General Contractor: CGC062888, and as an Underground Utility
              Contractor: CUC057151. Westra’s current bonding capacity is one
              hundred million dollars ($100,000,000) per single project and two
              hundred million dollars $200,000,000) aggregate.
            </p>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default AboutWestra;
