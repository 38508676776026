import { createSelector } from "reselect";

const getProjects = (state) => {
  return state.data.projects;
};

const getIdParam = (_state, props) => {
  return props.match.params["id"];
};

export const getProject = createSelector(
  getProjects,
  getIdParam,
  (projects, id) => {
    return projects.find(
      (p) => p.projname.replace(/\s+/g, "-").toLowerCase() === id
    );
  }
);

export const getLocationAttachments = (state) => {
  return state.data.locationAttachments;
};

export const getAlerts = (state) => {
  return state.data.alerts;
};
export const getAlertAttachments = (state) => {
  return state.data.alertAttachments;
};
