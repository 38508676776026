/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from "react";
import { IonItem, IonLabel, IonButton, IonModal, IonList } from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as selectors from "../../data/selectors";
// import Modal from "react-bootstrap/Modal";
import { connect } from "../../data/connect";
import ImageThumbmails from "../../components/ImageThumbails";
import LightboxModal from "../../components/LightboxModal/LightboxModal";

const AlertListItem = ({ alert, alertAttachments }) => {
  // Attachments
  const [images, setImages] = useState();
  const [documents, setDocuments] = useState();
  useEffect(() => {
    const attachments = alertAttachments.find(
      (a) => a.parentObjectId === alert.OBJECTID
    );
    if (attachments) {
      const images = attachments.attachmentInfos.filter((a) =>
        a.contentType.includes("image")
      );
      const documents = attachments.attachmentInfos.filter((a) =>
        a.contentType.includes("pdf")
      );
      setImages(images);
      setDocuments(documents);
      console.log("All Attachments:", attachments.attachmentInfos);
      console.log("Images:", images);
      console.log("Documents:", documents);
    }
  }, [alertAttachments, alert]);
  // Images Lightbox
  const [showLightboxModal, setShowLightboxModal] = useState(false);
  const [lightboxContent, setLightboxContent] = useState();
  const [initialSlide, setInitialSlide] = useState(0);
  const openLightbox = (index, images) => {
    setLightboxContent(images);
    setInitialSlide(index);
    setShowLightboxModal(true);
  };
  // Text conversions
  const createParagraphs = (text) => {
    return {
      __html: (text || "").replace(/:break/g, "<br /><br />"),
    };
  };
  const getAttachmentTitle = (title) => {
    return title.replace(/\.[^/.]+$/, "");
  };

  const [timeSince, setTimeSince] = useState("");
  useEffect(() => {
    const startTime = new Date(alert.startDate);
    const endTime = new Date();
    const timeDiff = endTime - startTime;
    function convertMS(ms) {
      let d;
      let h;
      let m;
      let s;
      s = Math.floor(ms / 1000);
      m = Math.floor(s / 60);
      s %= 60;
      h = Math.floor(m / 60);
      m %= 60;
      d = Math.floor(h / 24);
      h %= 24;

      return { d, h, m, s };
    }
    const timeSinceIssued = convertMS(timeDiff);
    const daysSince = timeSinceIssued.d.toString();
    setTimeSince(daysSince);
  }, [alert.startDate]);

  const getAlertVisibility = (alert) => {
    const currentDate = new Date();
    const startDate = new Date(alert.startDate);
    const endDate = new Date(alert.endDate);
    if (startDate < currentDate && endDate > currentDate) {
      return false;
    } else {
      return true;
    }
  };

  const dateFormatter = (timestamp) => {
    var dateStamp = new Date(timestamp);
    var month = [];
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";
    var m = month[dateStamp.getMonth()];
    var day = [];
    day[0] = "Sunday";
    day[1] = "Monday";
    day[2] = "Tuesday";
    day[3] = "Wednesday";
    day[4] = "Thursday";
    day[5] = "Friday";
    day[6] = "Saturday";
    // var day = dateStamp.getDay();
    var date = dateStamp.getDate();
    var year = dateStamp.getFullYear();
    var hours = dateStamp.getHours();
    var minutes = dateStamp.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var timeTxt = hours + ":" + minutes + ampm;
    return m + ` ` + date + `, ` + year + ` at ` + timeTxt;
  };

  return (
    <>
      <IonItem
        // button
        lines="none"
        target="_blank"
        key={alert.OBJECTID}
        className="alert-list-item"
        hidden={getAlertVisibility(alert)}
      >
        <IonLabel className="alert-label">
          <div
            style={{
              display: "-webkit-flex",
              display: "flex",
              WebkitJustifyContent: "space-between",
              justifyContent: "space-between",
              WebkitAlignItems: "center",
              alignItems: "center",
            }}
          >
            <h3>
              <FontAwesomeIcon
                icon={["fas", `${alert.icon}`]}
                color={`var(--ion-color-${alert.color})`}
                style={{
                  fontSize: "24px",
                  minWidth: "30px",
                  marginRight: "10px",
                }}
              />
              {alert.title}
            </h3>
            <span
              className="date"
              style={{
                display: "inline-block",
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.45)",
                position: "absolute",
                top: "13px",
                right: "23px",
              }}
            >
              {`${timeSince}d ago`}
            </span>
          </div>

          <p>
            <span style={{ fontWeight: "600", color: "#000" }}>Location: </span>
            {alert.location ? alert.location : "N/A"}
          </p>
          <p>
            <span style={{ fontWeight: "600", color: "#000" }}>
              Start Date:{" "}
            </span>
            {alert.startDate ? dateFormatter(alert.startDate) : "N/A"}
          </p>
          <p>
            <span style={{ fontWeight: "600", color: "#000" }}>End Date: </span>
            {alert.endDate ? dateFormatter(alert.endDate) : "TBD"}
          </p>
          <p
            style={{ margin: "10px 0px" }}
            dangerouslySetInnerHTML={createParagraphs(alert.description)}
          ></p>

          {documents && (
            <>
              <IonList
                class="project-list"
                style={{ maxWidth: "max-content", marginTop: "16px" }}
              >
                {documents.map((document) => (
                  <IonItem
                    lines="none"
                    key={document.id}
                    routerDirection="none"
                    href={document.url}
                    download={document.title}
                    detail={true}
                    target="_blank"
                    style={{ marginBottom: "3px" }}
                  >
                    <div slot="start" style={{ marginInlineEnd: "0px" }}>
                      <FontAwesomeIcon
                        color="var(--ion-color-primary)"
                        icon={["fas", `file-pdf`]}
                        style={{
                          fontSize: "22px",
                          minWidth: "30px",
                          marginTop: "4px",
                        }}
                      />
                    </div>
                    <IonLabel>
                      <h2
                        style={{
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                      >
                        {getAttachmentTitle(document.name)}
                      </h2>
                    </IonLabel>
                    <IonButton
                      fill="clear"
                      color="medium"
                      slot="end"
                      style={{ marginRight: "-5px", marginLeft: "2px" }}
                    >
                      View
                    </IonButton>
                  </IonItem>
                ))}
              </IonList>
            </>
          )}
        </IonLabel>
        {images && (
          <>
            <div className="block project-images">
              {images.map((image, index) => (
                <ImageThumbmails
                  onClick={() => openLightbox(index, images)}
                  src={image.url}
                  key={index}
                  alt="Image"
                />
              ))}
            </div>
            <IonModal
              isOpen={showLightboxModal}
              onDidDismiss={() => setShowLightboxModal(false)}
              swipeToClose={true}
              id="lightboxModal"
              backdropDismiss={false}
              mode="md"
            >
              <LightboxModal
                onDismissModal={() => setShowLightboxModal(false)}
                initialSlide={initialSlide}
                images={lightboxContent}
              />
            </IonModal>
          </>
        )}
      </IonItem>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    alertAttachments: selectors.getAlertAttachments(state),
  }),
  component: React.memo(AlertListItem),
});
