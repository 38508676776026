/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React from "react"; // useState
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonButton,
  IonRow,
  IonCol,
  IonSelectOption,
  IonSelect,
  IonGrid,
  IonItemDivider,
} from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import { useForm, Controller } from "react-hook-form";

const ProfileTypeForm = ({
  idToken,
  accessToken,
  onUpdateProfile,
  userMetadata,
}) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      hasProfile: "true",
      accountType: "",
    },
  });

  const handleSend = async (data) => {
    onUpdateProfile(data);
  };

  return (
    <IonGrid>
      <form id="contactForm">
        <IonList>
          <IonRow>
            <IonCol>
              <IonItemDivider
                style={{
                  "--background": "none",
                  "--padding-start": "5px",
                  marginTop: "-10px",
                  marginBottom: "5px",
                  marginLeft: "-5px",
                  fontWeight: "700",
                  fontSize: "16px",
                }}
              >
                Please choose a profile type to continue
              </IonItemDivider>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeXs="12" sizeSm="12">
              <IonItem mode="md" style={{ marginBottom: "15px" }}>
                <IonLabel position="floating">Profile Type</IonLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <IonSelect
                      onIonChange={(e) => onChange(e.detail.value)}
                      value={value}
                    >
                      <IonSelectOption value="individual">
                        Individual Applicant
                      </IonSelectOption>
                      <IonSelectOption value="business">
                        Business/Firm
                      </IonSelectOption>
                    </IonSelect>
                  )}
                  name="accountType"
                  control={control}
                  rules={{ required: true }}
                />
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                strong
                fill="solid"
                color="primary"
                inlist
                size="default"
                disabled={!isDirty || !isValid}
                onClick={handleSubmit((data) => handleSend(data))}
                data-testid="submit-button"
              >
                <IonIcon icon={chevronForward} slot="end"></IonIcon>
                Next
              </IonButton>
            </IonCol>
          </IonRow>
        </IonList>
      </form>
    </IonGrid>
  );
};

export default ProfileTypeForm;
