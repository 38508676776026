const locationsURL =
  "https://services7.arcgis.com/oQsBrSs8ovm1GWep/arcgis/rest/services/Tampa_WMI_-_Projects_(Public)/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryPoint&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Foot&returnGeodetic=false&outFields=*&returnHiddenFields=false&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson";

const locationAttachmentsURL =
  "https://services7.arcgis.com/oQsBrSs8ovm1GWep/arcgis/rest/services/Tampa_WMI_-_Projects_(Public)/FeatureServer/0/queryAttachments?objectIds=&globalIds=&definitionExpression=1%3D1&attachmentsDefinitionExpression=&attachmentTypes=&size=&keywords=&resultOffset=&resultRecordCount=&returnUrl=true&f=pjson&token=";

const alertsURL =
  "https://services7.arcgis.com/oQsBrSs8ovm1GWep/arcgis/rest/services/Tampa_WMI_-_Alerts_(Public)/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnHiddenFields=false&returnGeometry=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=json&token=";

const alertAttachmentsURL =
  "https://services7.arcgis.com/oQsBrSs8ovm1GWep/arcgis/rest/services/Tampa_WMI_-_Alerts_(Public)/FeatureServer/0/queryAttachments?objectIds=&globalIds=&definitionExpression=1%3D1&attachmentsDefinitionExpression=&attachmentTypes=&size=&keywords=&resultOffset=&resultRecordCount=&returnUrl=true&returnCountOnly=false&cacheHint=false&f=json&token=";

const dataURL = "https://fy20-wmi-website-default-rtdb.firebaseio.com/.json";

export const getConfData = async () => {
  const response = await Promise.all([
    fetch(locationsURL),
    fetch(locationAttachmentsURL),
    fetch(alertsURL),
    fetch(alertAttachmentsURL),
    fetch(dataURL),
  ]);
  const locationsData = await response[0].json();
  const locations = locationsData.features;
  const locationAttachmentsData = await response[1].json();
  const locationAttachments = locationAttachmentsData.attachmentGroups;
  const alertsResponse = await response[2].json();
  const alerts = alertsResponse.features;
  const alertAttachmentsResponse = await response[3].json();
  const alertAttachments = alertAttachmentsResponse.attachmentGroups;
  console.log("Alert Attachments:", alertAttachments);
  const siteData = await response[4].json();
  const projectFaqs = siteData.projectFaqs;
  const projectHighlights = siteData.projectHighlights;
  const projectBenefits = siteData.projectBenefits;
  const workforceFaqs = siteData.workforceFaqs;
  const wmbeFaqs = siteData.wmbeFaqs;
  const data = {
    locations,
    locationAttachments,
    alerts,
    alertAttachments,
    projectFaqs,
    projectHighlights,
    projectBenefits,
    workforceFaqs,
    wmbeFaqs,
  };

  return data;
};
