import React, { useState } from "react";
import {
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRouterLink,
  IonToolbar,
  IonButton,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import { useAuth0 } from "@auth0/auth0-react";
import { connect } from "../../data/connect";
import NavButtons from "../../components/Navigation/NavButtons";
import TampaLogo from "../../components/TampaLogo";
import Footer from "../../components/Footer/Footer";
import FAQs from "../../components/FAQs/FAQs";
import Divider from "../../components/Divider/Divider";
import AuthenticationButton from "../../auth/AuthenticationButton";
import WmbeSlbeProtected from "./WmbeSlbeProtected";
import WmbeSlbeUnprotected from "./WmbeSlbeUnprotected";
import ContactUs from "../../components/ContactUs";
import "./Workforce.scss";
import { personCircle } from "ionicons/icons";

const CONTACTS = [
  {
    id: "c1",
    title: "Call Us",
    subTitle: "(813) 207-0003 ext. 250",
    link: "tel:+1.813.207.0003",
    icon: "phone-plus",
    color: "secondary",
  },
  {
    id: "c2",
    title: "Email Us",
    subTitle: "wmbe-slbe@TampaWMI.com",
    link: "mailto:wmbe-slbe@TampaWMI.com",
    icon: "envelope-open-text",
    color: "secondary",
  },
];

const WmbeSlbe = ({ faqs }) => {
  const { isAuthenticated } = useAuth0();
  const [showAccount, setShowAccount] = useState(false);
  const handleToggleAccount = (value) => {
    setShowAccount(value);
  };
  return (
    <IonPage id="Workforce">
      <IonHeader>
        <IonToolbar>
          <IonRouterLink
            slot="start"
            routerLink={"/home"}
            routerDirection="none"
          >
            <div className="cot-logo-wrap">
              <TampaLogo />
            </div>
          </IonRouterLink>
          <IonButtons slot="end">
            <NavButtons />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="page-content">
        <div className="fixed-content">
          <section
            className="title-header"
            style={{
              backgroundImage: `url(assets/img/header-bg-2-gray.jpg)`,
            }}
          >
            <div className="overlay">
              <div className="container">
                <h1 style={{ marginBottom: "15px" }}>WMBE / SLBE</h1>

                <IonRow
                  style={{ maxWidth: "420px", margin: "0 auto 10px" }}
                  className="ion-align-items-center"
                >
                  <IonCol className="ion-text-center">
                    <img
                      src="assets/img/westra-logo-outlined.png"
                      alt="Westra Contruction logo"
                      width="400"
                      height="400"
                      style={{
                        maxWidth: "100px",
                        height: "auto",
                      }}
                    />
                  </IonCol>
                  <IonCol className="ion-text-center">
                    <img
                      src="assets/img/ariel-logo.png"
                      alt="Ariel Business Group logo"
                      width="400"
                      height="400"
                      style={{
                        maxWidth: "270px",
                        height: "auto",
                      }}
                    />
                  </IonCol>
                </IonRow>
                <p
                  className="blue-bg"
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontWeight: 500,
                    maxWidth: "500px",
                    margin: "10px auto 15px",
                    fontSize: "16px",
                  }}
                >
                  View project opportunities, helpful resources, and upcoming
                  events for Women/Minority and Small Local Business Enterprises
                </p>

                <AuthenticationButton returnTo="wmbe-slbe" />
                {/* {isAuthenticated && (
                  <>
                    <IonButton
                      fill="solid"
                      size="small"
                      color="secondary"
                      strong
                      style={{
                        marginLeft: "20px",
                        marginBottom: "85px",
                        minHeight: "40px",
                        minWidth: "130px",
                      }}
                      onClick={() => setShowAccount(true)}
                    >
                      <IonIcon slot="start" icon={personCircle} />
                      Edit Profile
                    </IonButton>
                  </>
                )} */}
              </div>
            </div>
          </section>
          <Divider type="wave" />
          <IonGrid fixed={true} className="content-grid">
            {isAuthenticated ? (
              <WmbeSlbeProtected
                showAccount={showAccount}
                onToggleAccount={handleToggleAccount}
              />
            ) : (
              <WmbeSlbeUnprotected />
            )}

            <FAQs FAQS={faqs} />
            <ContactUs
              contacts={CONTACTS}
              functionUrl="https://us-central1-fy20-wmi-website.cloudfunctions.net/wmbeSlbeContactForm"
            />
          </IonGrid>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    faqs: state.data.wmbeFaqs,
  }),
  component: React.memo(WmbeSlbe),
});
