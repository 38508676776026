/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { IonRouterOutlet } from "@ionic/react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "./data/connect";
import { useAuth0 } from "@auth0/auth0-react";
import {
  // logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { analytics } from "./index";
import $ from "jquery";

import Projects from "./pages/Projects/Projects";
import Workforce from "./pages/Workforce/Workforce";
import WmbeSlbe from "./pages/WmbeSlbe/WmbeSlbe";
import Contact from "./pages/Contact";
import Alerts from "./pages/Alerts/Alerts";
import Home from "./pages/Home";
import Menu from "./components/Navigation/Menu";
import Loading from "./components/Loading/Loading";
import Seo from "./components/Seo";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";

const Appp = ({ loading }) => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        // includedLanguages:
        //   "af,ga,sq,it,ar,ja,az,kn,eu,ko,bn,la,be,lv,bg,lt,ca,mk,zh-CN,ms,zh-TW,mt,hr,no,cs,fa,da,pl,nl,pt,en,ro,eo,ru,sr,tl,sk,fi,sl,fr,es,gl,sw,ka,sv,de,ta,el,te,gu,th,ht,tr,iw,uk,hi,ur,hu,vi,is,cy,id,yi",
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      "google_translate_element"
    );
    function changeGoogleStyles() {
      if ($(".goog-te-menu-frame").contents().find(".goog-te-menu2").length) {
        $(".goog-te-menu-frame").contents().find(".goog-te-menu2").css({
          "max-width": "100%",
          "overflow-x": "auto",
          "box-sizing": "border-box",
          height: "auto",
        });
      } else {
        setTimeout(changeGoogleStyles, 50);
      }
    }
    changeGoogleStyles();
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const { isLoading, user, isAuthenticated } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      setUserId(analytics, user.sub);
      setUserProperties(analytics, { account_id: user.sub });
    }
  }, [isAuthenticated]);

  if (isLoading || loading) {
    return <Loading />;
  }

  return (
    <>
      <Seo />
      <Menu />
      <IonRouterOutlet id="main">
        <Route path="/" render={() => <Home />} exact />
        <Route path="/projects" render={() => <Projects />} />
        <Route path="/alerts" render={() => <Alerts />} exact />
        <Route path="/contact" render={() => <Contact />} exact />
        <Route exact path="/workforce" render={() => <Workforce />} />
        <Route exact path="/wmbe-slbe" render={() => <WmbeSlbe />} />
        <Route exact path="/privacy-policy" render={() => <PrivacyPolicy />} />
        <Route
          exact
          path="/terms-and-conditions"
          render={() => <TermsConditions />}
        />
        <Route exact path="/home" render={() => <Redirect to="/" />} />
      </IonRouterOutlet>
      <div id="google_translate_element"></div>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    loading: state.data.loading,
  }),
  component: React.memo(Appp),
});
