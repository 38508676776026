import React from "react";
import Divider from "./Divider/Divider";
import "./PageHeader.scss";

const PageHeader = ({ title, subTitle, bgImage }) => {
  return (
    <>
      <section
        className="title-header"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="overlay">
          <div className="container">
            <h1 style={{ marginBottom: subTitle ? "20px" : "" }}>{title}</h1>
            {subTitle && (
              <p
                className="blue-bg"
                style={{
                  textAlign: "center",
                  color: "var(--ion-color-white)",
                  fontWeight: 500,
                  maxWidth: "500px",
                  margin: "0 auto 100px",
                  fontSize: "16px",
                }}
              >
                {subTitle}
              </p>
            )}
          </div>
        </div>
      </section>
      <Divider type="wave" />
    </>
  );
};

export default PageHeader;
