import React from "react";
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonToolbar,
  IonRouterLink,
} from "@ionic/react";
import { connect } from "../../data/connect";
import NavButtons from "../../components/Navigation/NavButtons";
import Footer from "../../components/Footer/Footer";
import TampaLogo from "../../components/TampaLogo";
import PageHeader from "../../components/PageHeader";
import AlertsList from "./AlertsList";
import "./Alerts.scss";

const Alerts = () => {
  return (
    <IonPage id="Alerts">
      <IonHeader>
        <IonToolbar>
          <IonRouterLink
            slot="start"
            routerLink={"/home"}
            routerDirection="none"
          >
            <div className="cot-logo-wrap">
              <TampaLogo />
            </div>
          </IonRouterLink>
          <IonButtons slot="end">
            <NavButtons />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="page-content">
        <div className="fixed-content">
          <PageHeader
            title="Alerts"
            subTitle="View currently issued alerts to stay in the know about road closures, schedule updates, public meetings, and more."
            bgImage="assets/img/header-bg-1-gray.jpg"
          />
          <IonGrid fixed={true} className="content-grid">
            <IonRow style={{ marginBottom: "20px" }}>
              <IonCol size="12" sizeXl="8" offsetXl="2">
                <IonRow>
                  <IonCol>
                    <AlertsList />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default connect({
  component: React.memo(Alerts),
});
