import React from 'react';
import { useLocation } from 'react-router-dom';
import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { warning, create, checkmarkCircle } from 'ionicons/icons';

import { connect } from '../../data/connect';

const ProjectList = ({ locations, onSelectProject }) => {
	const location = useLocation();

	function compare(a, b) {
		if (a.attributes.listorder < b.attributes.listorder) {
			return -1;
		}
		if (a.attributes.listorder > b.attributes.listorder) {
			return 1;
		}
		return 0;
	}

	const sortedLocations = locations.sort(compare);

	return (
		<>
			{sortedLocations.map((project) => (
				<IonItem
					lines='none'
					key={project.attributes.projname
						.replace(/\s+/g, '-')
						.toLowerCase()}
					detail={true}
					routerLink={`/projects/${project.attributes.projname
						.replace(/\s+/g, '-')
						.toLowerCase()}`}
					routerDirection='none'
					className={
						location.pathname ===
						`/projects/${project.attributes.projname
							.replace(/\s+/g, '-')
							.toLowerCase()}`
							? `selected-${project.attributes.projphase}`
							: `${project.attributes.projphase}`
					}
					onClick={onSelectProject}>
					<IonIcon
						slot='start'
						icon={
							project.attributes.projphase === 'P4: Construction'
								? warning
								: project.attributes.projphase === 'P2: Design'
								? create
								: checkmarkCircle
						}
						className='ion-no-margin'
						color={
							project.attributes.projphase === 'P4: Construction'
								? `inconstruction`
								: project.attributes.projphase === 'P2: Design'
								? `indesign`
								: `completed`
						}
					/>
					<IonLabel style={{ fontSize: '16px' }}>
						{project.attributes.title}
					</IonLabel>
				</IonItem>
			))}
		</>
	);
};

export default connect({
	mapStateToProps: (state) => ({
		locations: state.data.locations,
	}),
	component: React.memo(ProjectList),
});
