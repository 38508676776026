import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { IonButton, IonIcon } from "@ionic/react";
import { useLocation } from "react-router-dom";
import { logOut } from "ionicons/icons";

const LogoutButton = () => {
  const { logout } = useAuth0();
  const location = useLocation();
  return (
    <IonButton
      color="secondary"
      fill="solid"
      strong
      style={{
        marginLeft: "20px",
        marginBottom: "85px",
        minHeight: "40px",
        minWidth: "130px",
      }}
      size="small"
      onClick={() =>
        logout({
          returnTo: window.location.origin + location.pathname,
        })
      }
    >
      <IonIcon slot="start" icon={logOut} />
      Sign Out
    </IonButton>
  );
};

export default LogoutButton;
