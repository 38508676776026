import { IonLabel, IonItem, IonList, IonSkeletonText } from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logEvent } from "firebase/analytics";
import { analytics } from "..";

const ContactList = (props) => {
  const { data, detailText, loading } = props;

  const handleContactClick = (d) => {
    logEvent(analytics, "click_to_contact", { contact: d.subTitle });
  };
  return (
    <div className="hb-list">
      <IonList className="resource-list" style={{ background: "none" }}>
        {data.map((d) => (
          <IonItem
            lines="none"
            className="list-item"
            key={d.id}
            detail={d.link ? true : false}
            href={d.link && d.link}
            target="_blank"
            onClick={() => handleContactClick(d)}
          >
            <div slot="start">
              {!loading ? (
                <FontAwesomeIcon
                  color={`var(--ion-color-${d.color ? d.color : "secondary"})`}
                  icon={["fas", `${d.icon}`]}
                  style={{
                    fontSize: "30px",
                    minWidth: "30px",
                  }}
                />
              ) : (
                <IonSkeletonText
                  animated
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "0 auto",
                  }}
                />
              )}
            </div>
            <IonLabel className="hb-label">
              <span>
                {!loading ? (
                  d.title
                ) : (
                  <IonSkeletonText
                    animated
                    style={{
                      width: "170px",
                      height: "1.1rem",
                      borderRadius: "0px",
                    }}
                  />
                )}
              </span>

              {d.subTitle && (
                <h3>
                  {!loading ? (
                    d.subTitle
                  ) : (
                    <IonSkeletonText
                      animated
                      style={{
                        width: "100px",
                        height: "14px",
                        borderRadius: "0px",
                      }}
                    />
                  )}
                </h3>
              )}
            </IonLabel>
            <span slot="end" className="detailText">
              {detailText}
            </span>
          </IonItem>
        ))}
      </IonList>
    </div>
  );
};

export default ContactList;
