/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonButtons,
  IonCol,
  IonHeader,
  IonRow,
  IonToolbar,
  IonButton,
  useIonModal,
  IonModal,
  IonCard,
  IonCardContent,
} from "@ionic/react";
import { useAuth0 } from "@auth0/auth0-react";
import DashboardLoading from "./WorkforceLoading";
import ProfileTypeForm from "../../components/ProfileTypeForm";
import IndividualProfileForm from "../../components/IndividualProfileForm";
import BusinessProfileForm from "../../components/BusinessProfileForm";
import UserExportButton from "../../components/UserExportButton";
import IconList from "../../components/IconList";
import JobList from "../../components/JobList";
import axios from "axios";
import "./Workforce.scss";

const JOBOPENINGS = [
  {
    id: "J1",
    title: "Front-End Loader Operator",
    subTitle: "View Job Description",
    icon: "user-hard-hat",
    color: "secondary",
    purpose:
      "Operates straight or articulated rubber-tired tractor-type vehicle equipped with front-mounted hydraulically powered bucket or scoop to lift and transport bulk materials to and from storage or processing areas, to feed conveyors, hoppers, or chutes, and to load trucks or railcars",
    duties:
      "Starts engine, shifts gears, presses pedals, and turns steering wheel to operate loader. Moves levers to lower and tilt bucket and drives front-end loader forward to force bucket into bulk material. Moves levers to raise and tilt bucket when filled, drives vehicle to work site, and moves levers to dump material. Performs routine maintenance on loader, such as lubricating, fueling, and cleaning.",
    requirements:
      "Workers should be able to: Practice good safety habits; Work in dusty or dirty surroundings; Follow instructions and meet set standards; Judge distances and slopes accurately; and make decisions based on verifiable standards. Physically, workers must be able to: Stoop, bend, and crouch; Use hands, arms, and legs fully; See well (either naturally or with correction); and lift up to 50 pounds, with frequent lifting and carrying of objects weighing up to 25 pounds.",
  },
  {
    id: "J2",
    title: "Backhoe Operator",
    subTitle: "View Job Description",
    icon: "user-hard-hat",
    color: "secondary",
    purpose:
      "Operates backhoe (hydraulic shovel attached to tractor or train) which digs holes and trenches and removes and transports earth, gravel and other such materials.",
    duties:
      "Starts engine. Moves hand and foot levers to control hydraulic shovel. Uses shovel to dig trenches and holes of specified length, depth and width, remove loosened material and put in piles or transport trucks. Performs routine maintenance on equipment, such as lubricating, fueling, and cleaning",
    requirements:
      "Workers should be able to: Practice good safety habits; Work in dusty or dirty surroundings; Follow instructions and meet set standards; Judge distances and slopes accurately; and make decisions based on verifiable standards. Physically, workers must be able to: Stoop, bend, and crouch; Use hands, arms, and legs fully; See well (either naturally or with correction); and lift up to 50 pounds, with frequent lifting and carrying of objects weighing up to 25 pounds.",
  },
  {
    id: "J3",
    title: "Heavy-Equipment Operator",
    subTitle: "View Job Description",
    icon: "user-hard-hat",
    color: "secondary",
    purpose:
      "Operates several types of power construction equipment, such as compressors, pumps, hoists, derricks, cranes, shovels, tractors, scrapers, or motor graders, to excavate, move, and grade search, erect structural and reinforcing steel, and pour concrete or other hard surface paving materials.",
    duties:
      "Turns valves to control air and water output of compressors and pumps. Adjusts handwheels and depresses pedals to drive machines and control attachments, such as blades, buckets, scrapers, and swing booms. Repairs and maintains equipment.",
    requirements:
      "Workers should be able to: Read engineering drawing; Practice good safety habits; Work in dusty or dirty surroundings; Follow instructions and meet set standards; Judge distances and slopes accurately; and make decisions based on verifiable standards. Physically, workers must be able to: Stoop, bend, and crouch; Use hands, arms, and legs fully; See well (either naturally or with correction); and lift up to 50 pounds, with frequent lifting and carrying of objects weighing up to 25 pounds. Completion of apprenticeship program required.",
  },
];

const JOBOPENINGSSUB = [
  {
    id: "h1",
    title: "No Job Openings Available",
    subTitle: "Subcontractor Job Openings Will Be Posted Here",
    icon: "check",
    color: "secondary",
  },
];

const RESOURCES = [
  {
    id: "r1",
    title: "Westra Careers",
    subTitle: "https://www.westraconst.com/careers",
    link: "https://www.westraconst.com/careers",
    icon: "external-link",
    color: "secondary",
  },
  {
    id: "r2",
    title: "City of Tampa Careers",
    subTitle: "https://www.tampa.gov/careers",
    link: "https://www.tampa.gov/careers",
    icon: "external-link",
    color: "secondary",
  },
  {
    id: "r3",
    title: "Arie Business Group Training Academy",
    subTitle: "http://arielbusinessgroup.com/training-academy/",
    link: "http://arielbusinessgroup.com/training-academy/",
    icon: "external-link",
    color: "secondary",
  },
  {
    id: "r4",
    title: "Corporation to Develop Communities Tampa",
    subTitle: "https://www.cdcoftampa.org/",
    link: "https://www.cdcoftampa.org/",
    icon: "external-link",
    color: "secondary",
  },
  {
    id: "r5",
    title: "Career Resource Florida",
    subTitle: "https://careersourceflorida.com/",
    link: "https://careersourceflorida.com/",
    icon: "external-link",
    color: "secondary",
  },
  {
    id: "r6",
    title: "Associated Builders & Contractors Florida",
    subTitle: "https://www.abcflorida.com/",
    link: "https://www.abcflorida.com/",
    icon: "external-link",
    color: "secondary",
  },
  {
    id: "r7",
    title: "NABWIC Tampa",
    subTitle: "http://www.nawictampa.org/",
    link: "http://www.nawictampa.org/",
    icon: "external-link",
    color: "secondary",
  },
  {
    id: "r8",
    title: "NABWIC Chapters Areas",
    subTitle: "https://nabwic.org/page/ChaptersAreas",
    link: "https://nabwic.org/page/ChaptersAreas",
    icon: "external-link",
    color: "secondary",
  },
];

const EVENTS = [
  {
    id: "e1",
    title: "No Upcoming Events",
    subTitle: "Events will be posted here when announced.",
    icon: "check",
    color: "secondary",
  },
];

const ModalBody = ({ user, userToken, userMetadata, onSubmit, onLogout }) => (
  <>
    <IonHeader>
      <IonToolbar>
        <h3
          style={{
            marginBottom: "0px",
            color: "#fff",
            marginLeft: "15px",
            textAlign: "left",
          }}
        >
          Profile Setup
        </h3>

        <IonButtons slot="end">
          <IonButton
            onClick={onLogout}
            color="secondary"
            fill="solid"
            strong
            style={{
              marginRight: "10px",
              minHeight: "30px",
              minWidth: "100px",
              fontSize: "14px ",
              fontWeight: "600",
            }}
            size="small"
            mode="ios"
          >
            Sign Out
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <div className="modal-content-custom">
      <div className="modal-content-wrap">
        <ProfileTypeForm
          idToken={user.sub}
          accessToken={userToken}
          onUpdateProfile={onSubmit}
          userMetadata={userMetadata}
        />
      </div>
    </div>
  </>
);

const WorkforceProtected = ({ showAccount, onToggleAccount }) => {
  const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [userMetadata, setUserMetadata] = useState({});
  const [appMetadata, setAppMetadata] = useState();
  const [userToken, setUserToken] = useState(null);
  const [formSending, setFormSending] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      getUserMetadata();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (appMetadata) {
      if (appMetadata.roles[0] === "admin") {
        setIsAdmin(true);
      }
    }
  }, [appMetadata]);

  useEffect(() => {
    console.log(userMetadata);
  }, [userMetadata]);

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      setUserToken(accessToken);
      console.log(user);
      const metadataResponse = await fetch(
        `https://login.tampawmi.com/api/v2/users/${user.sub}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const { user_metadata, app_metadata } = await metadataResponse.json();
      setAppMetadata(app_metadata);
      setUserMetadata(user_metadata);

      console.log("user-metadata", user_metadata);
      if (user_metadata.hasProfile === "false") {
        present({ cssClass: "auto-height", backdropDismiss: false });
        return;
      }
      if (user_metadata.hasProfile === "true") {
        if (
          user_metadata.accountType === "individual" &&
          user_metadata.name === undefined
        ) {
          onToggleAccount(true);
        } else if (
          user_metadata.accountType === "business" &&
          user_metadata.fein === undefined
        ) {
          onToggleAccount(true);
        } else {
          setDashboardLoading(false);
          onToggleAccount(false);
        }
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleProfileType = async (data) => {
    setFormSending(true);
    const token = await getAccessTokenSilently();
    var options = {
      method: "PATCH",
      url: `https://login.tampawmi.com/api/v2/users/${user.sub}`,
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      data: {
        user_metadata: data,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        getUserMetadata();
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {
        dismiss();
        setFormSending(false);
      });
  };

  const handleProfileSubmit = async (profile) => {
    setFormSending(true);
    const token = await getAccessTokenSilently();
    const individualData = {
      name: profile.data.name,
      phone: profile.data.phone,
      zipCode: profile.data.zipCode,
      gender: profile.data.gender,
      ethnicity: profile.data.ethnicity,
      educationMilitary:
        "Highschool: " +
        profile.data.highSchool +
        ", " +
        "College: " +
        profile.data.college +
        ", " +
        "Armed Forces: " +
        profile.data.armedForces,
      experience:
        "Preferred Trade: " +
        profile.data.preferredTrade +
        ", " +
        "Years of Experience: " +
        profile.data.yearsOfExperience +
        ", " +
        "Training Experience: " +
        profile.data.trainingExperience +
        ", " +
        "Skills & Abilities: " +
        profile.data.skillsAndAbilities,
    };
    // const wmbeSlbeData = {
    //   businessName: profile.data.legalName,
    //   fein: profile.data.fein,
    //   address:
    //     profile.data.address +
    //     " " +
    //     profile.data.city +
    //     ", " +
    //     profile.data.state +
    //     " " +
    //     profile.data.zipCode,
    //   phoneNumbers:
    //     "Primary: " +
    //     profile.data.phone +
    //     ", " +
    //     "Secondary: " +
    //     profile.data.phoneOther,
    //   website: profile.data.website,
    //   background:
    //     "Years In Business: " +
    //     profile.data.yearsInBusiness +
    //     ", " +
    //     "Number of Employees: " +
    //     profile.data.numberEmployees +
    //     ", " +
    //     "Number of Offices: " +
    //     profile.data.numberOffices,
    //   certifications:
    //     "Woman Owned: " +
    //     profile.data.womanOwned +
    //     ", " +
    //     "Minority Owned: " +
    //     profile.data.minorityOwned +
    //     ", " +
    //     "Small/Local: " +
    //     profile.data.smallLocal,
    //   owner:
    //     "Name: " +
    //     profile.data.ownerName +
    //     ", " +
    //     "Email: " +
    //     profile.data.ownerEmail +
    //     ", " +
    //     "Phone: " +
    //     profile.data.ownerPhone +
    //     ", " +
    //     "Gender: " +
    //     profile.data.ownerGender +
    //     ", " +
    //     "Ethnicity: " +
    //     profile.data.ownerEthnicity,
    //   primaryContact:
    //     "Name: " +
    //     profile.data.primaryName +
    //     ", " +
    //     "Title: " +
    //     profile.data.primaryTitle +
    //     ", " +
    //     "Email: " +
    //     profile.data.primaryEmail,
    //   jobOpenings: profile.data.jobOpenings,
    //   //   "Permanent: " +
    //   //   profile.data.permanentJobOpenings +
    //   //   ", " +
    //   //   "Part-time/seasonal: " +
    //   //   profile.data.seasonalJobOpenings,
    //   // jobDescriptions: profile.data.jobDescriptions,
    // };
    var options = {
      method: "PATCH",
      url: `https://login.tampawmi.com/api/v2/users/${user.sub}`,
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      data: {
        // user_metadata: profile.data,
        user_metadata:
          userMetadata.accountType === "individual"
            ? individualData
            : profile.data,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        getUserMetadata();
        if (!profile.hasCompletedProfile) {
          return axios.post(
            `https://us-central1-fy20-wmi-website.cloudfunctions.net/newProfile`,
            response.data
          );
        }
        return console.log("Just Update Profile");
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {
        dismiss();
        setFormSending(false);
      });
  };

  const handleFormLogout = () => {
    logout({
      returnTo: window.location.origin + window.location.pathname,
    });
  };

  const [present, dismiss] = useIonModal(ModalBody, {
    user,
    userToken,
    userMetadata,
    onSubmit: handleProfileType,
    onLogout: handleFormLogout,
  });

  return (
    <>
      {dashboardLoading && <DashboardLoading />}
      {!dashboardLoading && (
        <>
          <IonRow>
            <IonCol size="12" sizeLg="12" sizeXl="10" offsetXl="1">
              <IonRow>
                <IonCol className="ion-text-center">
                  {isAdmin && <UserExportButton />}
                  <h2
                    className="home"
                    style={{
                      textAlign: "center",
                      marginBottom: "15px",
                    }}
                  >
                    Job <strong> Openings</strong>
                  </h2>
                </IonCol>
              </IonRow>
              <IonRow style={{ marginBottom: "20px" }}>
                <IonCol size="12" sizeSm="12" sizeMd="6" sizeXl="6">
                  <IonCard className="card-margin">
                    <IonCardContent>
                      {userMetadata && (
                        <JobList
                          title="Westra Construction"
                          icon="westra"
                          jobs={JOBOPENINGS}
                          userMetadata={userMetadata}
                          user={user}
                        />
                      )}
                    </IonCardContent>
                  </IonCard>
                </IonCol>
                <IonCol size="12" sizeSm="12" sizeMd="6" sizeXl="6">
                  <IonCard className="card-margin">
                    <IonCardContent>
                      <IconList
                        title="Subcontractor "
                        icon="industry-alt"
                        iconColor="primary"
                        data={JOBOPENINGSSUB}
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <h2
                    className="home"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Helpful <strong>Information</strong>
                  </h2>
                </IonCol>
              </IonRow>
              <IonRow style={{ marginBottom: "20px" }}>
                <IonCol size="12" sizeSm="12" sizeMd="" sizeXl="6">
                  <IonCard className="card-margin">
                    <IonCardContent>
                      <IconList
                        title="Resources"
                        icon="toolbox"
                        iconColor="primary"
                        data={RESOURCES}
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>

                <IonCol size="12" sizeSm="12" sizeMd="6" sizeXl="6">
                  <IonCard className="card-margin">
                    <IonCardContent>
                      <IconList
                        title="Job Seeker Events"
                        icon="calendar-star"
                        iconColor="primary"
                        data={EVENTS}
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </>
      )}
      <IonModal
        isOpen={showAccount}
        onDidDismiss={() => onToggleAccount(false)}
        cssClass="profile-modal"
        mode="ios"
        backdropDismiss={false}
      >
        <IonHeader>
          <IonToolbar>
            <h3
              style={{
                marginBottom: "0px",
                color: "#fff",
                marginLeft: "15px",
                textAlign: "left",
              }}
            >
              {dashboardLoading ? "Profile Setup" : "Edit Profile"}
            </h3>
            <IonButtons slot="end">
              {dashboardLoading ? (
                <IonButton
                  onClick={handleFormLogout}
                  color="secondary"
                  fill="solid"
                  strong
                  style={{
                    marginRight: "10px",
                    minHeight: "30px",
                    minWidth: "100px",
                    fontSize: "14px ",
                    fontWeight: "600",
                  }}
                  size="small"
                  mode="ios"
                >
                  Sign Out
                </IonButton>
              ) : (
                <IonButton
                  strong
                  color="white"
                  onClick={() => onToggleAccount(false)}
                >
                  Cancel
                </IonButton>
              )}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {userMetadata.accountType === "individual" && (
          <IndividualProfileForm
            idToken={user.sub}
            accessToken={userToken}
            onSubmit={handleProfileSubmit}
            userMetadata={userMetadata}
            dashboardLoading={dashboardLoading}
            formSending={formSending}
          />
        )}
        {userMetadata.accountType === "business" && (
          <BusinessProfileForm
            idToken={user.sub}
            accessToken={userToken}
            onSubmit={handleProfileSubmit}
            userMetadata={userMetadata}
            dashboardLoading={dashboardLoading}
            formSending={formSending}
          />
        )}
      </IonModal>
    </>
  );
};

export default WorkforceProtected;
