import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from "./reportWebVitals";

import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD7oAH8NFd7Bc_2iTegysMTYPwS7f1B70U",
  authDomain: "fy20-wmi-website.firebaseapp.com",
  projectId: "fy20-wmi-website",
  storageBucket: "fy20-wmi-website.appspot.com",
  messagingSenderId: "1094784430971",
  appId: "1:1094784430971:web:d36ae4102bfa717d5383de",
  measurementId: "G-B9NRE35C4J",
};

// eslint-disable-next-line no-unused-vars
export const firebaseApp = initializeApp(firebaseConfig, {
  automaticDataCollectionEnabled: false,
});
export const analytics = initializeAnalytics(firebaseApp, {
  config: { send_page_view: false },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
