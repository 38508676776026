/* eslint-disable default-case */

export const appReducer = (state, action) => {
  switch (action.type) {
    case "set-app-loading": {
      return { ...state, loading: action.isLoading };
    }
    case "set-network-status": {
      return { ...state, networkStatus: action.networkStatus };
    }
    case "set-app-data": {
      return { ...state, ...action.data };
    }
    case "update-filtered-project-categories": {
      return {
        ...state,
        filteredProjectCategories: action.filteredProjectCategories,
      };
    }
    case "update-filtered-alert-categories": {
      return {
        ...state,
        filteredAlertCategories: action.filteredAlertCategories,
      };
    }
    case "set-project-search-text": {
      return { ...state, projectSearchText: action.projectSearchText };
    }
    case "set-alert-search-text": {
      return { ...state, alertSearchText: action.alertSearchText };
    }
    case "set-menu-enabled": {
      return { ...state, menuEnabled: action.menuEnabled };
    }
  }
};
