import { combineReducers } from "./combineReducers";
import { appReducer } from "./projects/projects.reducer";
// import { userReducer } from "./user/user.reducer";

export const initialState = {
  data: {
    locations: [],
    locationAttachments: [],
    alerts: [],
    alertAttachments: [],
    projectFaqs: [],
    projectHighlights: [],
    projectBenefits: [],
    workforceFaqs: [],
    loading: true,
  },
  user: {
    hasSeenTutorial: false,
    themeSetting: "light",
    darkMode: false,
    isLoggedin: false,
    loading: false,
    textSize: "medium",
    favoritedProjects: [],
    locationPermission: "",
    location: [],
  },
};

export const reducers = combineReducers({
  data: appReducer,
  // user: userReducer,
});
