/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import {
  IonItem,
  IonLabel,
  IonList,
  IonInput,
  IonButton,
  IonRow,
  IonCol,
  IonGrid,
  IonSelectOption,
  IonSelect,
  IonSpinner,
  IonCheckbox,
  IonItemDivider,
  IonTextarea,
  IonContent,
} from "@ionic/react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { logEvent } from "firebase/analytics";
import { analytics } from "..";

const BusinessProfileForm = ({
  onSubmit,
  userMetadata,
  dashboardLoading,
  formSending,
}) => {
  const [hasCompletedProfile, setHasCompletedProfile] = useState(false);

  function handleSend(data) {
    if (!hasCompletedProfile) {
      // finish profile setup
      onSubmit({ data, hasCompletedProfile });
      logEvent(analytics, "sign_up");
    } else {
      // update profile
      onSubmit({ data, hasCompletedProfile });
      logEvent(analytics, "update_profile");
    }
  }

  useEffect(() => {
    if (userMetadata.fein === undefined) {
      // profile not completed
      setHasCompletedProfile(false);
    } else {
      setHasCompletedProfile(true);
      // profile completed
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    legalName: Yup.string().required("* Firm/Business Name is required"),
    fein: Yup.string()
      .required("* FEIN is required")
      .matches(/^\d{9}$/, "* FEIN must be nine digits in length"),
    address: Yup.string().required("* Address is required"),
    website: Yup.string().url("* Please enter a valid URL"),
    yearsInBusiness: Yup.number().required("* Years in business is required"),
    numberEmployees: Yup.number().required("* Number of employees is required"),
    numberOffices: Yup.number().required("* Number of offices is required"),
    ownerName: Yup.string().required("* Owner name is required"),
    ownerEmail: Yup.string()
      .email("* Please enter a valid email address")
      .required("* Email is required"),
    ownerPhone: Yup.string()
      .matches(
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        "Please enter a 10 digit phone number"
      )
      .required("* Phone number is required"),
    ownerGender: Yup.string().required("* Gender is required"),
    ownerEthnicity: Yup.string().required("* Ethnicity is required"),
    primaryName: Yup.string().required("* Name is required"),
    primaryEmail: Yup.string()
      .email("* Please enter a valid email address")
      .required("* Email address is equired"),
    primaryPhone: Yup.string()
      .matches(
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        "Please enter a 10 digit phone number"
      )
      .required("* Phone number is required"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      legalName: userMetadata ? userMetadata.legalName : "",
      fein: userMetadata ? userMetadata.fein : "",
      address: userMetadata ? userMetadata.address : "",
      website: userMetadata ? userMetadata.website : "",
      yearsInBusiness: userMetadata ? userMetadata.yearsInBusiness : "",
      numberEmployees: userMetadata ? userMetadata.numberEmployees : "",
      numberOffices: userMetadata ? userMetadata.numberOffices : "",
      minorityOwned: userMetadata ? userMetadata.minorityOwned : false,
      womanOwned: userMetadata ? userMetadata.womanOwned : false,
      smallLocal: userMetadata ? userMetadata.smallLocal : false,
      ownerName: userMetadata ? userMetadata.ownerName : "",
      ownerEmail: userMetadata ? userMetadata.ownerEmail : "",
      ownerPhone: userMetadata ? userMetadata.ownerPhone : "",
      ownerGender: userMetadata ? userMetadata.ownerGender : "",
      ownerEthnicity: userMetadata ? userMetadata.ownerEthnicity : "",
      primaryName: userMetadata ? userMetadata.primaryName : "",
      primaryEmail: userMetadata ? userMetadata.primaryEmail : "",
      primaryPhone: userMetadata ? userMetadata.primaryPhone : "",
      jobOpenings: userMetadata ? userMetadata.jobOpenings : "",
    },
  });

  return (
    <>
      <IonContent className="ion-padding">
        <div className="modal-content-custom">
          <div className="modal-content-wrap">
            <IonGrid>
              <form id="businessForm">
                <IonList>
                  {dashboardLoading && (
                    <IonRow>
                      <IonCol>
                        <IonItemDivider
                          style={{
                            "--background": "none",
                            "--padding-start": "5px",
                            marginTop: "-10px",
                            marginBottom: "5px",
                            marginLeft: "-5px",
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                        >
                          Please fill out the following information to complete
                          your profile and access the WMBE-SLBE dashboard.
                        </IonItemDivider>
                      </IonCol>
                    </IonRow>
                  )}
                  <IonRow>
                    <IonCol>
                      <IonItemDivider
                        style={{
                          "--background": "none",
                          "--padding-start": "5px",
                          marginTop: "7px",
                          marginBottom: "5px",
                          marginLeft: "-5px",
                          fontWeight: "700",
                          fontSize: "16px",
                        }}
                      >
                        Business Information:
                      </IonItemDivider>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItem mode="md">
                        <IonLabel position="stacked">
                          Legal Name of Firm/Business
                        </IonLabel>
                        <Controller
                          name="legalName"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="text"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.legalName && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.legalName.message}
                        </p>
                      )}
                    </IonCol>
                    <IonCol>
                      <IonItem mode="md">
                        <IonLabel position="stacked">
                          Employment Identification Number (FEIN)
                        </IonLabel>
                        <Controller
                          name="fein"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="number"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.fein && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.fein.message}
                        </p>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" sizeSm="7" sizeMd="6">
                      <IonItem mode="md">
                        <IonLabel position="stacked">
                          Address, City, State, Zip Code
                        </IonLabel>
                        <Controller
                          name="address"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="text"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.address && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.address.message}
                        </p>
                      )}
                    </IonCol>
                    <IonCol size="12" sizeSm="5" sizeMd="6">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Website</IonLabel>
                        <Controller
                          name="website"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="url"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                              placeholder="https://example.com"
                            />
                          )}
                        />
                      </IonItem>
                      {errors.website && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.website.message}
                        </p>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" sizeSm="4" sizeMd="4">
                      <IonItem mode="md">
                        <IonLabel position="stacked">
                          Years in business
                        </IonLabel>
                        <Controller
                          name="yearsInBusiness"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="number"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.yearsInBusiness && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.yearsInBusiness.message}
                        </p>
                      )}
                    </IonCol>
                    <IonCol size="12" sizeSm="4" sizeMd="4">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Total Employees</IonLabel>
                        <Controller
                          name="numberEmployees"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="number"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.numberEmployees && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.numberEmployees.message}
                        </p>
                      )}
                    </IonCol>
                    <IonCol size="12" sizeSm="4" sizeMd="4">
                      <IonItem mode="md">
                        <IonLabel position="stacked">
                          Offices/Locations
                        </IonLabel>
                        <Controller
                          name="numberOffices"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="number"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.numberOffices && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.numberOffices.message}
                        </p>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" sizeSm="4">
                      <IonItem mode="md" style={{ "--padding-start": "0px" }}>
                        <IonLabel
                          style={{ paddingLeft: "16px", fontSize: "12px" }}
                        >
                          Minority Owned
                        </IonLabel>
                        <Controller
                          name="minorityOwned"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonCheckbox
                              id="minorityOwned"
                              slot="end"
                              color="primary"
                              checked={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => {
                                onChange(e.detail.checked);
                              }}
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" sizeSm="4">
                      <IonItem mode="md" style={{ "--padding-start": "0px" }}>
                        <IonLabel
                          style={{ paddingLeft: "16px", fontSize: "12px" }}
                        >
                          Woman Owned
                        </IonLabel>
                        <Controller
                          name="womanOwned"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonCheckbox
                              id="womanOwned"
                              slot="end"
                              color="primary"
                              checked={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => {
                                onChange(e.detail.checked);
                              }}
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" sizeSm="4">
                      <IonItem mode="md" style={{ "--padding-start": "0px" }}>
                        <IonLabel
                          style={{ paddingLeft: "16px", fontSize: "12px" }}
                        >
                          Small / Local Business
                        </IonLabel>
                        <Controller
                          name="smallLocal"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonCheckbox
                              id="smallLocal"
                              slot="end"
                              color="primary"
                              checked={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => {
                                onChange(e.detail.checked);
                              }}
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItemDivider
                        style={{
                          "--background": "none",
                          "--padding-start": "5px",
                          marginTop: "7px",
                          marginBottom: "5px",
                          marginLeft: "-5px",
                          fontWeight: "700",
                          fontSize: "16px",
                        }}
                      >
                        Owner Information:
                      </IonItemDivider>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" sizeSm="12" sizeMd="4">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Name / Title</IonLabel>
                        <Controller
                          name="ownerName"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="text"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.ownerName && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.ownerName.message}
                        </p>
                      )}
                    </IonCol>
                    <IonCol size="12" sizeSm="6" sizeMd="4">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Email Address</IonLabel>
                        <Controller
                          name="ownerEmail"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="email"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.ownerEmail && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.ownerEmail.message}
                        </p>
                      )}
                    </IonCol>
                    <IonCol size="12" sizeSm="6" sizeMd="4">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Phone Number</IonLabel>
                        <Controller
                          name="ownerPhone"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="tel"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol sizeXs="12" sizeSm="6">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Gender</IonLabel>
                        <Controller
                          name="ownerGender"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonSelect
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            >
                              <IonSelectOption value="FEMALE">
                                Female
                              </IonSelectOption>
                              <IonSelectOption value="MALE">
                                Male
                              </IonSelectOption>
                              <IonSelectOption value="NOTSAY">
                                Other
                              </IonSelectOption>
                            </IonSelect>
                          )}
                        />
                      </IonItem>
                      {errors.ownerGender && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.ownerGender.message}
                        </p>
                      )}
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="6">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Ethnicity</IonLabel>
                        <Controller
                          name="ownerEthnicity"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonSelect
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            >
                              <IonSelectOption value="African American">
                                African American
                              </IonSelectOption>
                              <IonSelectOption value="Hispanic American">
                                Hispanic American
                              </IonSelectOption>
                              <IonSelectOption value="Asian American">
                                Asian American
                              </IonSelectOption>
                              <IonSelectOption value="Native American">
                                Native American
                              </IonSelectOption>
                              <IonSelectOption value="Caucausian American">
                                Caucasian American
                              </IonSelectOption>
                              <IonSelectOption value="Other">
                                Other
                              </IonSelectOption>
                            </IonSelect>
                          )}
                        />
                      </IonItem>
                      {errors.ownerEthnicity && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.ownerEthnicity.message}
                        </p>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItemDivider
                        style={{
                          "--background": "none",
                          "--padding-start": "5px",
                          marginTop: "7px",
                          marginBottom: "5px",
                          marginLeft: "-5px",
                          fontWeight: "700",
                          fontSize: "16px",
                        }}
                      >
                        Primary Contact:
                      </IonItemDivider>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" sizeSm="12" sizeMd="4">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Name / Title</IonLabel>
                        <Controller
                          name="primaryName"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="text"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.primaryName && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.primaryName.message}
                        </p>
                      )}
                    </IonCol>
                    <IonCol size="12" sizeSm="6" sizeMd="4">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Email Address</IonLabel>
                        <Controller
                          name="primaryEmail"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="email"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.primaryEmail && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.primaryEmail.message}
                        </p>
                      )}
                    </IonCol>
                    <IonCol size="12" sizeSm="6" sizeMd="4">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Phone Number</IonLabel>
                        <Controller
                          name="primaryPhone"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="tel"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.primaryPhone && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.primaryPhone.message}
                        </p>
                      )}
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <IonItemDivider
                        style={{
                          "--background": "none",
                          "--padding-start": "5px",
                          marginTop: "7px",
                          marginBottom: "5px",
                          marginLeft: "-5px",
                          fontWeight: "700",
                          fontSize: "16px",
                        }}
                      >
                        Job Openings:
                      </IonItemDivider>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItem mode="md">
                        <Controller
                          name="jobOpenings"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonTextarea
                              rows="4"
                              type="text"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                              placeholder="Please list and provide a description for any permanent or part time/seasonal job openings currently available at your business."
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <span className="disclaimer">
                        Disclaimer: Requested information is submitted
                        voluntarily and not required. Information collected,
                        including internet protocol (IP) addresses are not
                        redistributed or sold to intermediaries or third
                        parties.
                      </span>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginTop: "20px" }}>
                    <IonCol>
                      <IonButton
                        strong
                        fill="solid"
                        size="default"
                        expand="block"
                        color="primary"
                        onClick={handleSubmit((data) => handleSend(data))}
                      >
                        {formSending ? (
                          <IonSpinner
                            slot="end"
                            name="lines-small"
                            className="cip-spinner"
                          />
                        ) : !dashboardLoading ? (
                          "Update Profile"
                        ) : (
                          "Submit Profile"
                        )}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonList>
              </form>
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </>
  );
};

export default BusinessProfileForm;
