/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import {
  IonItem,
  IonLabel,
  IonList,
  IonInput,
  IonButton,
  IonRow,
  IonCol,
  IonGrid,
  IonTextarea,
  IonSpinner,
  IonIcon,
  useIonToast,
} from "@ionic/react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { logEvent } from "firebase/analytics";
import { analytics } from "..";
import { send } from "ionicons/icons";
import axios from "axios";

const ContactForm = ({ functionUrl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [present, dismiss] = useIonToast();

  function handleSend(data) {
    logEvent(analytics, "update_profile");
    setIsLoading(true);
    axios
      .post(`${functionUrl}`, data)
      .then(function (response) {
        if (response.status === 200) {
          present({
            duration: 2000,
            buttons: [{ text: "Hide", handler: () => dismiss() }],
            animated: true,
            position: "bottom",
            color: "dark",
            cssClass: "bottom-toast",
            message: "Message sent successfully",
          });
        }
      })
      .catch(function (error) {
        present({
          duration: 2000,
          buttons: [{ text: "Hide", handler: () => dismiss() }],
          animated: true,
          position: "bottom",
          color: "dark",
          cssClass: "bottom-toast",
          message: "Message could not be sent.",
        });
      })
      .finally(() => {
        logEvent(analytics, "send_contact_message");
        setIsLoading(false);
        reset(
          {
            name: "",
            email: "",
            message: "",
          },
          {
            keepDefaultValues: true,
            keepIsValid: true,
          }
        );
      });
  }
  // form validation rules
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("* Name is required"),
    email: Yup.string().email().required("* Email is required"),
    message: Yup.string().required("* Message is required"),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fullName: "",
      email: "",
      message: "",
    },
  });

  return (
    <IonGrid>
      <form className="businessForm">
        <IonList>
          <IonRow>
            <IonCol sizeXs="12" sizeSm="6">
              <IonItem mode="md">
                <IonLabel position="stacked">Your Name</IonLabel>
                <Controller
                  name="fullName"
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <IonInput
                      type="text"
                      value={value}
                      onIonBlur={onBlur}
                      onIonChange={(e) => onChange(e.detail.value)}
                    />
                  )}
                />
              </IonItem>
              {errors.fullName && (
                <p
                  style={{
                    fontSize: "12px",
                    marginLeft: "16px",
                    marginTop: "7px",
                    color: "var(--ion-color-danger)",
                  }}
                >
                  {errors.fullName.message}
                </p>
              )}
            </IonCol>
            <IonCol size="12" sizeSm="6">
              <IonItem mode="md">
                <IonLabel position="stacked">Email Address</IonLabel>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <IonInput
                      type="text"
                      value={value}
                      onIonBlur={onBlur}
                      onIonChange={(e) => onChange(e.detail.value)}
                    />
                  )}
                />
              </IonItem>
              {errors.email && (
                <p
                  style={{
                    fontSize: "12px",
                    marginLeft: "16px",
                    marginTop: "7px",
                    color: "var(--ion-color-danger)",
                  }}
                >
                  {errors.email.message}
                </p>
              )}
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="12" sizeSm="12">
              <IonItem mode="md">
                <IonLabel position="stacked">Message</IonLabel>
                <Controller
                  name="message"
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <IonTextarea
                      rows="3"
                      type="text"
                      value={value}
                      onIonBlur={onBlur}
                      onIonChange={(e) => onChange(e.detail.value)}
                    />
                  )}
                />
              </IonItem>
              {errors.message && (
                <p
                  style={{
                    fontSize: "12px",
                    marginLeft: "16px",
                    marginTop: "7px",
                    color: "var(--ion-color-danger)",
                  }}
                >
                  {errors.message.message}
                </p>
              )}
            </IonCol>
          </IonRow>

          <IonRow style={{ marginTop: "0px" }}>
            <IonCol>
              <IonButton
                strong
                fill="solid"
                size="default"
                // expand="block"
                color="primary"
                onClick={handleSubmit((data) => handleSend(data))}
              >
                {isLoading ? (
                  <IonSpinner
                    slot="end"
                    name="lines-small"
                    className="cip-spinner"
                  />
                ) : (
                  <IonIcon icon={send} slot="end"></IonIcon>
                )}
                <span style={{ marginRight: "7px" }}>Send Message</span>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonList>
      </form>
    </IonGrid>
  );
};

export default ContactForm;
