import React from "react";
import { IonRow, IonCol, IonCard, IonCardContent } from "@ionic/react";
import ContactList from "./ContactList";
import ContactForm from "./ContactForm";

const ContactUs = ({ contacts, noHeading, functionUrl }) => {
  return (
    <>
      {!noHeading && (
        <IonRow>
          <IonCol>
            <h2
              className="home"
              style={{
                textAlign: "center",
                marginBottom: "0px",
              }}
            >
              Reach <strong>Out</strong>
            </h2>
          </IonCol>
        </IonRow>
      )}

      <IonRow style={{ marginBottom: "30px" }}>
        <IonCol size="12" sizeXl="10" offsetXl="1">
          <IonRow>
            <IonCol>
              <IonRow style={{ marginBottom: "0px" }}>
                <IonCol size="12" sizeSm="12" sizeMd="7" sizeXl="7">
                  <IonCard style={{ margin: "0px 5px" }}>
                    <IonCardContent>
                      <h3
                        style={{
                          textAlign: "center",
                          fontSize: "25px",
                          marginBottom: "17px",
                          color: "#000",
                        }}
                      >
                        Contact Form
                      </h3>
                      <ContactForm functionUrl={functionUrl} />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
                <IonCol size="12" sizeSm="12" sizeMd="5" sizeXl="5">
                  <IonCard style={{ margin: "0px 5px" }}>
                    <IonCardContent>
                      <h3
                        style={{
                          textAlign: "center",
                          fontSize: "25px",
                          marginBottom: "17px",
                          color: "#000",
                        }}
                      >
                        Phone / Email
                      </h3>
                      <ContactList data={contacts} />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  );
};

export default ContactUs;
