import React from "react";
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRouterLink,
  IonRow,
  IonToolbar,
  IonCard,
  IonCardContent,
} from "@ionic/react";
import { connect } from "../data/connect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavButtons from "../components/Navigation/NavButtons";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer/Footer";
import TampaLogo from "../components/TampaLogo";
import FAQs from "../components/FAQs/FAQs";

const Home = ({ faqs, highlights, benefits }) => {
  return (
    <IonPage id="Home">
      <IonHeader>
        <IonToolbar>
          <IonRouterLink
            slot="start"
            routerLink={"/home"}
            routerDirection="none"
          >
            <div className="cot-logo-wrap">
              <TampaLogo />
            </div>
          </IonRouterLink>
          <IonButtons slot="end">
            <NavButtons />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="page-content">
        <div className="fixed-content">
          <PageHeader
            title="
            FY20 CIP Water Main Improvements Design-Build Project
            "
            bgImage="assets/img/header-bg-1-gray.jpg"
          />
          <IonGrid fixed={true} className="content-grid">
            <IonRow
              style={{ maxWidth: "1100px", margin: "0px auto 20px" }}
              className="ion-align-items-center"
            >
              <IonCol className="ion-text-center">
                <img
                  src="assets/img/cot-logo-blue.png"
                  alt="City of Tampa Florida logo"
                  width="500"
                  height="326"
                  style={{
                    maxWidth: "240px",
                    height: "auto",
                  }}
                />
              </IonCol>
              <IonCol className="ion-text-center">
                <img
                  src="assets/img/t3-logo.png"
                  alt="Mayor Jane Castor Transforming Tampa's Tomorrow logo"
                  width="500"
                  height="153"
                  style={{
                    maxWidth: "80vw",
                    width: "350px",
                    height: "auto",
                    margin: "0px 15px 25px 15px",
                  }}
                />
              </IonCol>
              <IonCol className="ion-text-center">
                <img
                  src="assets/img/pipes-logo.png"
                  alt="PIPES Progressive Infrastructure Planning to Ensure Sustainability logo"
                  width="500"
                  height="254"
                  style={{
                    maxWidth: "230px",
                    height: "auto",
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" sizeXl="10" offsetXl="1">
                <IonRow style={{ marginBottom: "20px", padding: "0px 20px" }}>
                  <IonCol>
                    <h3
                      style={{
                        textAlign: "center",
                        fontSize: "28px",
                        fontWeight: "500",
                        color: "#000",
                        marginBottom: "20px",
                      }}
                    >
                      About The <strong> Project</strong>
                    </h3>
                    <p>
                      The City’s investment in its water delivery infrastructure
                      is key to the delivery of water to its customers for
                      drinking, cooking, cleaning, sanitation, irrigation,
                      recreation, business, industry, and fire suppression. The
                      City’s Capital Improvement Program (CIP) identifies areas
                      where water main upgrades, production plant improvements,
                      major distribution system repairs and expansions are
                      needed. The CIP also provides a timeline to complete
                      needed work and identifies funding to move projects
                      forward. 
                    </p>
                    <p>
                      The FY20 CIP Water Main Replacements project is part of
                      the City’s Capital Improvement Program (CIP) and consists
                      of the design and construction of potable water
                      distribution pipe and appurtenances for various
                      non-contiguous water main replacement projects throughout
                      the central portion of the City’s water service area.
                    </p>
                    <p>
                      This project is part of the City of Tampa’s Progressive
                      Infrastructure Plan to Ensure Sustainability (PIPES). With
                      PIPES, the City of Tampa is investing in Tampa's tomorrow
                      by taking a proactive approach to renew our
                      infrastructure, prevent breakdowns, and provide long-term,
                      permanent fixes to our water and wastewater systems.
                    </p>
                  </IonCol>
                </IonRow>
                <IonRow style={{ marginBottom: "0px" }}>
                  <IonCol
                    size="12"
                    sizeMd="6"
                    offsetMd=""
                    style={{ marginBottom: "10px" }}
                  >
                    <div className="hb-list">
                      <h3
                        style={{
                          textAlign: "center",
                          fontSize: "28px",
                          marginBottom: "10px",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        Project <strong> Highlights</strong>
                      </h3>
                      <IonList>
                        {highlights.map((highlight) => (
                          <IonItem
                            lines="none"
                            key={highlight.id}
                            style={{ "--background": " #f0f9fe" }}
                          >
                            <div slot="start">
                              <FontAwesomeIcon
                                color="var(--ion-color-primary)"
                                icon={["fas", `${highlight.icon}`]}
                                style={{
                                  fontSize: "30px",
                                  minWidth: "40px",
                                }}
                              />
                            </div>

                            <IonLabel className="hb-label">
                              <span>{highlight.text}</span>
                            </IonLabel>
                          </IonItem>
                        ))}
                      </IonList>
                    </div>
                  </IonCol>
                  <IonCol
                    size="12"
                    sizeMd="6"
                    offsetMd=""
                    style={{ marginBottom: "10px" }}
                  >
                    <div className="hb-list">
                      <h3
                        style={{
                          textAlign: "center",
                          fontSize: "28px",
                          marginBottom: "10px",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        Community <strong> Benefits</strong>
                      </h3>
                      <IonList>
                        {benefits.map((benefit) => (
                          <IonItem
                            lines="none"
                            key={benefit.id}
                            style={{ "--background": " #f0f9fe" }}
                          >
                            <div slot="start">
                              <FontAwesomeIcon
                                color="var(--ion-color-primary)"
                                icon={["fas", `${benefit.icon}`]}
                                style={{
                                  fontSize: "30px",
                                  minWidth: "40px",
                                }}
                              />
                            </div>

                            <IonLabel className="hb-label">
                              <span>{benefit.text}</span>
                            </IonLabel>
                          </IonItem>
                        ))}
                      </IonList>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
            <FAQs FAQS={faqs} />
          </IonGrid>
          <div className="shape-divider">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 100"
              preserveAspectRatio="none"
            >
              <path
                className="shape-divider-fill"
                d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"
              ></path>
            </svg>
          </div>
          <div className="blue-bg-gradient">
            <IonGrid fixed={true} className="ada-statement">
              <IonRow>
                <IonCol
                  size="12"
                  sizeLg="10"
                  offsetLg="1"
                  sizeXl="10"
                  offsetXl="1"
                >
                  <IonCard
                    className="content-card ion-margin-bottom"
                    style={{
                      padding: "20px",
                    }}
                  >
                    <IonCardContent>
                      <h3
                        className="home"
                        style={{ fontSize: "28px", marginTop: "0px" }}
                      >
                        ADA <strong>Accommodations</strong>
                      </h3>
                      <p style={{ color: "#000", fontSize: "1rem" }}>
                        In accordance with the Americans with Disabilities Act
                        (“ADA”) and Section 286.26, Florida Statutes, persons
                        with disabilities needing a reasonable accommodation to
                        participate in this public hearing or meeting should
                        contact the City of Tampa’s ADA Coordinator at least 48
                        hours prior to the proceeding.
                      </p>
                      <br />
                      <p style={{ color: "#000", fontSize: "1rem" }}>
                        The ADA Coordinator may be contacted via phone at{" "}
                        <a href="tel:+1.813.274.3964">813-274-3964</a>, email at{" "}
                        <a href="mailto:TampaADA@tampagov.net">
                          TampaADA@tampagov.net
                        </a>
                        , or by submitting an ADA – Accommodations Request form
                        available online at tampagov.net/ADARequest.
                      </p>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    faqs: state.data.projectFaqs,
    highlights: state.data.projectHighlights,
    benefits: state.data.projectBenefits,
  }),
  component: React.memo(Home),
});
