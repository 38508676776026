import { IonCol, IonRow } from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import "./Workforce.scss";

const WorkforceUnprotected = () => {
  return (
    <IonRow>
      <IonCol size="12" sizeLg="12" offsetLg="" sizeXl="10" offsetXl="1">
        <IonRow style={{ marginTop: "20px", marginBottom: "40px" }}>
          <IonCol>
            <p
              style={{
                margin: "0px auto",
                maxWidth: "1250px",
                textAlign: "justify",
                padding: "0px 17px",
                fontSize: "18px",
                color: "#000",
              }}
            >
              <strong> WESTRA Construction</strong> supports the City of Tampa
              in achieving its workforce goals and ultimately, enlarging the
              pool of skilled talent needed to complete important City projects.
              More specifically, we want to hire construction workers and
              tradespeople and help advance the trades that support our efforts
              throughout the City.
            </p>
          </IonCol>
        </IonRow>

        <IonRow style={{ marginBottom: "30px" }}>
          <IonCol size="12" sizeMd="4">
            <div className="hb-list icon-box">
              <div
                style={{
                  display: "block",
                  textAlign: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={["fad", "bullseye-arrow"]}
                  style={{
                    fontSize: "70px",
                    marginBottom: "15px",
                  }}
                  color="var(--ion-color-primary)"
                />
              </div>
              <h2>
                Our <strong>Goal</strong>
              </h2>
              <p>
                To ensure retention of skilled labor on projects and work to
                build a database of hirable talent for our contracts. To invest
                in and engage prospective talent.
              </p>
            </div>
          </IonCol>
          <IonCol size="12" sizeMd="4">
            <div className="hb-list icon-box">
              <div
                style={{
                  display: "block",
                  textAlign: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={["fad", "handshake"]}
                  style={{
                    fontSize: "70px",
                    marginBottom: "15px",
                  }}
                  color="var(--ion-color-primary)"
                />
              </div>
              <h2>
                Our <strong>Commitment</strong>
              </h2>
              <p>
                To ensure we recruit and hire a diverse group of skilled talent
                for current projects. We have a dedicated team of individuals
                ready to share resources and when needed, recommend training or
                apprenticeship opportunities to ensure all applicants become
                hirable. Whether it’s in-person or virtual training, we support
                all applicants who want to be better-trained.
              </p>
            </div>
          </IonCol>
          <IonCol size="12" sizeMd="4">
            <div className="hb-list icon-box">
              <div
                style={{
                  display: "block",
                  textAlign: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={["fad", "user-hard-hat"]}
                  style={{
                    fontSize: "70px",
                    marginBottom: "15px",
                  }}
                  color="var(--ion-color-primary)"
                />
              </div>
              <h2>
                Your <strong>Future</strong>
              </h2>
              <p style={{ marginBottom: "0px" }}>
                Just received a certification? We want to connect with you!
                Create Account, complete your client profile, and then share
                your information with us to ensure any questions you have can be
                answered.
              </p>
            </div>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default WorkforceUnprotected;
