import React, { useEffect } from "react";
import { IonButton, IonMenuButton } from "@ionic/react";
import { useLocation } from "react-router-dom";
import Translate from "../Translate";

const NavButtons = () => {
  let location = useLocation();
  const [mQuery, setMQuery] = React.useState({
    matches: window.innerWidth > 768 ? true : false,
  });

  useEffect(() => {
    let mediaQuery = window.matchMedia("(min-width: 905px)");
    mediaQuery.addListener(setMQuery);

    return () => mediaQuery.removeListener(setMQuery);
  }, []);

  return (
    <div>
      {mQuery && !mQuery.matches ? (
        <IonMenuButton color="white" />
      ) : (
        <>
          <IonButton
            color="white"
            routerLink={"/"}
            routerDirection="none"
            className={`horizontal-menu-button ${
              location.pathname === "/" && "button-active"
            }`}
          >
            Home
          </IonButton>
          <IonButton
            color="white"
            routerLink={"/projects/holmes-street"}
            routerDirection="none"
            className={`horizontal-menu-button ${
              location.pathname.includes("projects") && "button-active"
            }`}
          >
            Projects
          </IonButton>
          <IonButton
            color="white"
            routerLink={"/alerts"}
            routerDirection="none"
            className={`horizontal-menu-button ${
              location.pathname === "/alerts" && "button-active"
            }`}
          >
            Alerts
          </IonButton>

          <IonButton
            color="white"
            routerLink={"/workforce"}
            routerDirection="none"
            className={`horizontal-menu-button ${
              location.pathname.includes("workforce") && "button-active"
            }`}
          >
            Workforce
          </IonButton>

          <IonButton
            color="white"
            routerLink={"/wmbe-slbe"}
            routerDirection="none"
            className={`horizontal-menu-button ${
              location.pathname === "/wmbe-slbe" && "button-active"
            }`}
          >
            WMBE/SLBE
          </IonButton>

          <IonButton
            color="white"
            routerLink={"/contact"}
            routerDirection="none"
            className={`horizontal-menu-button ${
              location.pathname === "/contact" && "button-active"
            }`}
          >
            Contact
          </IonButton>
        </>
      )}
    </div>
  );
};

export default NavButtons;
