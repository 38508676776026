import React, { useRef } from "react";
import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonToolbar,
  IonRouterLink,
  IonCardHeader,
  IonList,
} from "@ionic/react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "../../data/connect";
import { AnimatePresence, motion } from "framer-motion";
import NavButtons from "../../components/Navigation/NavButtons";
import TampaLogo from "../../components/TampaLogo";
import Footer from "../../components/Footer/Footer";
import ProjectList from "./ProjectList";
import ProjectListPopover from "./ProjectListPopover";
import ProjectCard from "./ProjectCard/ProjectCard";
import ArcGisMap from "./ArcGisMap";
import "./Projects.scss";

const Projects = ({ locations }) => {
  const routerRef = useRef(null);

  return (
    <IonPage id="Projects">
      <IonHeader>
        <IonToolbar>
          <IonRouterLink
            slot="start"
            routerLink={"/home"}
            routerDirection="none"
          >
            <div className="cot-logo-wrap">
              <TampaLogo />
            </div>
          </IonRouterLink>
          <IonButtons slot="end">
            <NavButtons />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="page-content">
        <div className="fixed-content">
          <IonGrid className="ion-no-padding">
            <IonRow className="projects-row">
              <IonCol
                sizeXl="2.5"
                sizeLg="3"
                sizeMd="0"
                sizeSm="0"
                sizeXs="0"
                className="project-list-responsive"
              >
                <IonCard mode="ios" className="project-list sticky-content">
                  <IonCardHeader>
                    <IonCardTitle className="project-list">
                      <div
                        style={{
                          width: "100%",
                          padding: "0px 0px 0px 0px",
                          marginBottom: "15px",
                        }}
                      >
                        <h4>
                          <b>Legend</b>
                        </h4>
                        <img
                          src="assets/img/tampa-wmi-legend.png"
                          alt="City of Tampa Florida logo"
                          width="500"
                          height="326"
                          style={{
                            maxWidth: "160px",
                            height: "auto",
                          }}
                        />
                      </div>
                      <span
                        className="focused-title"
                        tabIndex="-1"
                        // ref={projectListTitle}
                      >
                        Projects
                      </span>
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList className="project-list">
                      <ProjectList />
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </IonCol>

              <IonCol
                sizeXl="5.5"
                sizeLg="5"
                pushLg="0"
                sizeMd="6"
                pushMd="6"
                sizeSm="12"
                sizeXs="12"
              >
                <ArcGisMap locations={locations} />
              </IonCol>
              <IonCol
                sizeXl="4"
                sizeLg="4"
                pullLg="0"
                sizeMd="6"
                pullMd="6"
                sizeSm="12"
                sizeXs="12"
                className="ion-text-center"
              >
                <ProjectListPopover />
                <IonCard
                  mode="ios"
                  className="project-detail ion-text-left  no-margin-mobile"
                >
                  <span
                    className="focused-title"
                    tabIndex="-1"
                    // ref={projectTitle}
                  ></span>
                  <AnimatePresence exitBeforeEnter>
                    <Switch ref={routerRef}>
                      {locations.map((route, index) => (
                        <Route
                          key={index}
                          path={`/projects/${route.attributes.projname
                            .replace(/\s+/g, "-")
                            .toLowerCase()}`}
                          exact
                          render={() => (
                            <motion.div
                              initial={{
                                opacity: 0,
                              }}
                              animate={{
                                opacity: 1,
                              }}
                              exit={{
                                opacity: 0,
                              }}
                            >
                              {
                                <ProjectCard
                                  projectSlug={route.attributes.projname
                                    .replace(/\s+/g, "-")
                                    .toLowerCase()}
                                  router={routerRef.current}
                                />
                              }
                            </motion.div>
                          )}
                        />
                      ))}

                      <Redirect
                        exact
                        from="/projects"
                        to="/projects/columbus-drive-wm-replacement"
                      />
                    </Switch>
                  </AnimatePresence>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    locations: state.data.locations,
  }),
  component: Projects,
});
