import React, { useEffect, useState } from "react";
import { IonItem, IonLabel, IonIcon, IonList } from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";
import * as selectors from "../../data/selectors";
import AlertsListItem from "./AlertsListItem";
import { connect } from "../../data/connect";

const AlertsList = ({ alerts }) => {
  const [activeAlerts, setActiveAlerts] = useState(alerts);
  console.log(alerts);
  useEffect(() => {
    const currentDate = new Date();
    activeAlerts.forEach((alert) => {
      const startDate = new Date(alert.attributes.startDate);
      const endDate = new Date(alert.attributes.endDate);
      if (startDate < currentDate && endDate > currentDate) {
        return;
      } else {
        const activeAlerts = alerts.filter((a) => a !== alert);
        setActiveAlerts(activeAlerts);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAlerts]);

  if (activeAlerts.length === 0) {
    return (
      <IonList
        className="alert-list"
        style={{ maxWidth: "400px", margin: "0 auto" }}
      >
        <IonItem lines="none" className="alert-list-item">
          <IonIcon
            ios={checkmarkCircle}
            slot="start"
            size="large"
            color="success"
            className="alert-icon"
          />
          <IonLabel className="alert-label">
            <span style={{ fontSize: "18px" }}>No Active Alerts</span>
            <br />
            <span style={{ fontSize: "14px", color: "#00000090" }}>
              Alerts will appear here when they are issued.
            </span>
          </IonLabel>
        </IonItem>
      </IonList>
    );
  }

  return (
    <div>
      {activeAlerts.map((alert) => (
        <IonList
          className="alert-list"
          style={{ marginTop: "0px", marginBottom: "20px" }}
          key={alert.attributes.OBJECTID}
        >
          <AlertsListItem alert={alert.attributes} />
        </IonList>
      ))}
    </div>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    alerts: selectors.getAlerts(state),
    alertAttachments: selectors.getAlertAttachments(state),
  }),
  component: React.memo(AlertsList),
});
