import React from "react";
import {
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonToolbar,
  IonRouterLink,
} from "@ionic/react";
import { connect } from "../data/connect";
import NavButtons from "../components/Navigation/NavButtons";
import Footer from "../components/Footer/Footer";
import ContactUs from "../components/ContactUs";
import TampaLogo from "../components/TampaLogo";
import PageHeader from "../components/PageHeader";
import "./Contact.scss";

const CONTACTS = [
  {
    id: "c1",
    title: "Call the 24/7 Hotline",
    subTitle: "(813) 649-3384",
    link: "tel:+1.813.649.3384",
    icon: "phone-plus",
    color: "secondary",
  },
  {
    id: "c2",
    title: "Send an Email",
    subTitle: "info@TampaWMI.com",
    link: "mailto:info@TampaWMI.com",
    icon: "envelope-open-text",
    color: "secondary",
  },
];

const Contact = () => {
  return (
    <IonPage id="Contact">
      <IonHeader>
        <IonToolbar>
          <IonRouterLink
            slot="start"
            routerLink={"/home"}
            routerDirection="none"
          >
            <div className="cot-logo-wrap">
              <TampaLogo />
            </div>
          </IonRouterLink>
          <IonButtons slot="end">
            <NavButtons />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="page-content">
        <div className="fixed-content">
          <PageHeader
            title="Contact"
            subTitle="Have questions or concerns? Contact a project
                      representative by phone, email, or message using form below."
            bgImage="assets/img/header-bg-2-gray.jpg"
          />
          <IonGrid fixed={true} className="content-grid">
            <ContactUs
              noHeading
              contacts={CONTACTS}
              functionUrl="https://us-central1-fy20-wmi-website.cloudfunctions.net/contactPageForm"
            />
          </IonGrid>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default connect({
  component: React.memo(Contact),
});
