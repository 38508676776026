import { IonCol, IonRow } from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AboutWestra from "./AboutWestra";

const WmbeSlbeUnprotected = () => {
  return (
    <>
      <IonRow>
        <IonCol size="12" sizeLg="12" offsetLg="" sizeXl="10" offsetXl="1">
          <IonRow style={{ marginTop: "20px", marginBottom: "40px" }}>
            <IonCol>
              <p
                style={{
                  margin: "0px auto",
                  maxWidth: "1250px",
                  textAlign: "justify",
                  padding: "0px 17px",
                  fontSize: "18px",
                }}
              >
                <strong> WESTRA Construction</strong> supports the City of Tampa
                in achieving its WMBE and SLBE goals. As a result, we want to
                connect with WMBE and SLBE firms as we complete and pursue
                important City of Tampa projects. More specifically, we want to
                meet and build relationships with firms who are interested in
                joining us as we work to make important City infrastructure and
                other improvements. We want to engage WMBE/SLBE firms at every
                stage of every project we undertake!
              </p>
            </IonCol>
          </IonRow>
          <IonRow style={{ marginBottom: "30px" }}>
            <IonCol size="12" sizeMd="4">
              <div className="hb-list icon-box">
                <div
                  style={{
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={["fad", "handshake"]}
                    style={{
                      fontSize: "70px",
                      marginBottom: "15px",
                    }}
                    color="var(--ion-color-primary)"
                  />
                </div>
                <h2>
                  Our <strong>Commitment</strong>
                </h2>
                <p>
                  To ensure we partner with a diverse group of WMBE/SLBE firms
                  for current and future projects. We want to connect with you!
                </p>
              </div>
            </IonCol>
            <IonCol size="12" sizeMd="4">
              <div className="hb-list icon-box">
                <div
                  style={{
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={["fad", "bullseye-arrow"]}
                    style={{
                      fontSize: "70px",
                      marginBottom: "15px",
                    }}
                    color="var(--ion-color-primary)"
                  />
                </div>
                <h2>
                  Our <strong>Goal</strong>
                </h2>
                <p>
                  To ensure utilization of WMBE/SLBE firms on projects and work
                  to build a database of firms for current and future projects.
                  We want transparent and supportive relationships with
                  WMBE/SLBE firms.
                </p>
              </div>
            </IonCol>
            <IonCol size="12" sizeMd="4">
              <div className="hb-list icon-box">
                <div
                  style={{
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={["fad", "user-tie"]}
                    style={{
                      fontSize: "70px",
                      marginBottom: "15px",
                    }}
                    color="var(--ion-color-primary)"
                  />
                </div>
                <h2>
                  Your <strong>Future</strong>
                </h2>
                <p style={{ marginBottom: "0px" }}>
                  Create Account, complete the WMBE/SLBE form, and share your
                  preferred scopes and other relevant information with us to
                  ensure any questions you have can be answered.
                </p>
              </div>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <AboutWestra />
    </>
  );
};

export default WmbeSlbeUnprotected;
