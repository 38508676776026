import React, { useEffect } from "react";
import {
  IonMenu,
  IonContent,
  IonList,
  IonMenuToggle,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { useLocation, withRouter } from "react-router-dom";
import { connect } from "../../data/connect";
import "./Menu.scss";
import TampaLogo from "../TampaLogo";

const Menu = ({}) => {
  const location = useLocation();

  return (
    <IonMenu
      side="start"
      contentId="main"
      type="overlay"
      className="menu-left"
      id="menuLeft"
    >
      <IonContent className="menu-content">
        <div className="cot-logo-wrap">
          <TampaLogo blue />
        </div>
        <IonList id="menu-list">
          <IonMenuToggle autoHide={false}>
            <IonItem
              className={location.pathname === "/" ? "selected" : ""}
              routerLink="/"
              routerDirection="root"
              lines="none"
              // detail={location.pathname === "/" ? true : false}
            >
              <IonLabel>Home</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonItem
              className={
                location.pathname.includes("projects") ? "selected" : ""
              }
              routerLink="/projects/holmes-street"
              routerDirection="root"
              lines="none"
              // detail={
              //   location.pathname === "/projects/holmes-street" ? true : false
              // }
            >
              <IonLabel>Projects</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonItem
              className={location.pathname === "/alerts" ? "selected" : ""}
              routerLink="/alerts"
              routerDirection="root"
              lines="none"
              // detail={location.pathname === "/alerts" ? true : false}
            >
              <IonLabel>Alerts</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              className={location.pathname === "/workforce" ? "selected" : ""}
              routerLink="/workforce"
              routerDirection="root"
              lines="none"
              // detail={location.pathname === "/workforce" ? true : false}
            >
              <IonLabel>Workforce</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonItem
              className={location.pathname === "/wmbe-slbe" ? "selected" : ""}
              routerLink="/wmbe-slbe"
              routerDirection="root"
              lines="none"
              detail={location.pathname === "/wmbe-slbe" ? true : false}
            >
              <IonLabel>WMBE-SLBE</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonItem
              className={location.pathname === "/contact" ? "selected" : ""}
              routerLink="/contact"
              routerDirection="root"
              lines="none"
              // detail={location.pathname === "/contact" ? true : false}
            >
              <IonLabel>Contact</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    // isLoggedin: state.user.isLoggedin,
  }),
  component: withRouter(Menu),
});
