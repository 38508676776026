import React from "react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogOutButton";
import { useAuth0 } from "@auth0/auth0-react";

const AuthenticationButton = ({ returnTo }) => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? (
    <LogoutButton />
  ) : (
    <LoginButton returnTo={returnTo} />
  );
};

export default AuthenticationButton;
