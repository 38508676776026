import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "../data/connect";
import { Helmet } from "react-helmet";
import { analytics } from "../index";
import { logEvent } from "firebase/analytics";

function truncateMetaDescription(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

const Seo = (props) => {
  let location = useLocation();
  let currentUrl = "https://tampawmi.com" + location.pathname;
  let quote = props.quote !== undefined ? props.quote : "";
  let image =
    props.image !== undefined
      ? props.image
      : "https://tampawmi.com/assets/share-img.jpg";
  let hashtag = props.hashtag !== undefined ? props.hashtag : "#TampaWMI";
  const [title, setTitle] = useState("Tampa Water Main Improvements");
  const [description, setDescription] = useState(
    "The City of Tampa's official website for the water main improvements happening throughout the city. View project information and stay up to date with real time alerts."
  );

  useEffect(() => {
    // const slug = location.pathname.split("/").pop();
    // const slugSpaced = slug.replace(/-/g, " ");
    // const pageTitle = slugSpaced.replace(/\w\S*/g, (w) =>
    //   w.replace(/^\w/, (c) => c.toUpperCase())
    // );
    // if (location.pathname.includes("projects")) {
    //   const project = props.locations.find(
    //     (p) => p.attributes.projname.replace(/\s+/g, "-").toLowerCase() === slug
    //   );
    //   const selectedProject = project.attributes;
    //   const description =
    //     "Learn about The City of Tampa's " +
    //     selectedProject.title +
    //     "water main improvement project. " +
    //     selectedProject.projdesc;
    //   setDescription(truncateMetaDescription(description, 250));
    //   setTitle(selectedProject.title);
    //   return;
    // }
    if (location.pathname === "/") {
      setTitle("Home");
      setDescription(
        "The City of Tampa's official website for the water main improvements happening throughout the city. View project information and stay up to date with real time alerts."
      );
    }
    if (location.pathname === "/alerts") {
      setTitle("Alerts");
      setDescription(
        "View currently issued alerts for water main improvements happening throughout the City of Tampa. Stay in the know about road closures, detours, and more."
      );
    }
    if (location.pathname === "/workforce") {
      setTitle("Workforce");
      setDescription(
        "View current job openings, apply for work, access job seeker resources for the City of Tampa's Water Main Improvements project."
      );
    }
    if (location.pathname === "/wmbe-slbe") {
      setTitle("WMBE/SLBE");
      setDescription(
        "View Women/Minority and Small Local Business Enterprises opportunities, resources, and more for the City of Tampa's Water Main Improvements Project."
      );
    }
    if (location.pathname === "/contact") {
      setTitle("Contact");
      setDescription(
        "Have questions or concerns about a water main improvement project? Reach out to the project representative by phone, email, or contact form any time time of the day."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (title === "Tampa Water Main Improvements") {
    } else {
      logEvent(analytics, "page_view", { page_title: title });
    }
  }, [title]);

  return (
    <>
      <Helmet titleTemplate="%s | Tampa Water Main Improvments" title={title}>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta property="type" content="website" />
        <meta property="url" content={currentUrl} />
        <meta name="robots" content="noodp" />
        <meta property="title" content={title} />
        <meta property="quote" content={quote} />
        <meta name="description" content={description} />
        <meta property="image" content={image} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:quote" content={quote} />
        <meta property="og:hashtag" content={hashtag} />
        <meta property="og:image" content={image} />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:site_name" content="Tampa WMI" />
        <meta property="og:description" content={description} />
      </Helmet>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    locations: state.data.locations,
  }),
  component: Seo,
});
