/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import {
  IonItem,
  IonLabel,
  IonList,
  IonInput,
  IonButton,
  IonRow,
  IonCol,
  IonGrid,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonItemDivider,
  IonContent,
  IonCheckbox,
} from "@ionic/react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { logEvent } from "firebase/analytics";
import { analytics } from "..";

const IndividualProfileForm = ({
  onSubmit,
  userMetadata,
  dashboardLoading,
  formSending,
}) => {
  const [hasCompletedProfile, setHasCompletedProfile] = useState(false);

  function handleSend(data) {
    if (!hasCompletedProfile) {
      // finish profile setup
      onSubmit({ data, hasCompletedProfile });
      logEvent(analytics, "sign_up");
    } else {
      // update profile
      onSubmit({ data, hasCompletedProfile });
      logEvent(analytics, "update_profile");
    }
  }

  useEffect(() => {
    if (userMetadata.name === undefined) {
      // profile not completed
      setHasCompletedProfile(false);
    } else {
      setHasCompletedProfile(true);
      // profile completed
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("* First and last name is required"),
    phone: Yup.string()
      .matches(
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        "Please enter a 10 digit phone number"
      )
      .required("* Phone number is required"),
    zipCode: Yup.string().required("* Zip code is required"),
    gender: Yup.string().required("* Gender is required"),
    ethnicity: Yup.string().required("* Ethnicity is required"),
    preferredTrade: Yup.string().required("* Preferred trade is required"),
    yearsOfExperience: Yup.number().required("* Required"),
    trainingExperience: Yup.string().required(
      "* Training experience is required"
    ),
    skillsAndAbilities: Yup.string().required(
      "* Skills and abilities is required"
    ),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: userMetadata ? userMetadata.name : "",
      phone: userMetadata ? userMetadata.phone : "",
      zipCode: userMetadata ? userMetadata.zipCode : "",
      gender: userMetadata ? userMetadata.gender : "",
      ethnicity: userMetadata ? userMetadata.ethnicity : "",
      preferredTrade: userMetadata ? userMetadata.preferredTrade : "",
      yearsOfExperience: userMetadata ? userMetadata.yearsOfExperience : "",
      highSchool: userMetadata ? userMetadata.highSchool : false,
      college: userMetadata ? userMetadata.college : false,
      armedForces: userMetadata ? userMetadata.armedForces : false,
      trainingExperience: userMetadata ? userMetadata.trainingExperience : "",
      skillsAndAbilities: userMetadata ? userMetadata.skillsAndAbilities : "",
    },
  });

  return (
    <>
      <IonContent className="ion-padding">
        <div className="modal-content-custom">
          <div className="modal-content-wrap">
            <IonGrid>
              <form id="businessForm">
                <IonList>
                  {dashboardLoading && (
                    <IonRow>
                      <IonCol>
                        <IonItemDivider
                          style={{
                            "--background": "none",
                            "--padding-start": "5px",
                            marginTop: "-10px",
                            marginBottom: "5px",
                            marginLeft: "-5px",
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                        >
                          Please fill out the following information to complete
                          your profile and access the Workforce dashboard.
                        </IonItemDivider>

                        <div className="disclaimer">
                          Requested information is submitted voluntarily and not
                          required. Information collected, including internet
                          protocol (IP) addresses are not redistributed or sold
                          to intermediaries or third parties.
                        </div>
                      </IonCol>
                    </IonRow>
                  )}
                  <IonRow>
                    <IonCol sizeXs="12" sizeSm="5">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Name</IonLabel>
                        <Controller
                          name="name"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="text"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.name && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.name.message}
                        </p>
                      )}
                    </IonCol>
                    <IonCol size="12" sizeSm="4.5">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Phone Number</IonLabel>
                        <Controller
                          name="phone"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="tel"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.phone && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.phone.message}
                        </p>
                      )}
                    </IonCol>
                    <IonCol size="4" sizeSm="2.5">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Zip Code</IonLabel>
                        <Controller
                          name="zipCode"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="number"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.zipCode && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.zipCode.message}
                        </p>
                      )}
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol sizeXs="12" sizeSm="2">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Gender</IonLabel>
                        <Controller
                          name="gender"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonSelect
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            >
                              <IonSelectOption value="FEMALE">
                                Female
                              </IonSelectOption>
                              <IonSelectOption value="MALE">
                                Male
                              </IonSelectOption>
                              <IonSelectOption value="NOTSAY">
                                Other
                              </IonSelectOption>
                            </IonSelect>
                          )}
                        />
                      </IonItem>
                      {errors.gender && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.gender.message}
                        </p>
                      )}
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="3">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Ethnicity</IonLabel>
                        <Controller
                          name="ethnicity"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonSelect
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            >
                              <IonSelectOption value="African American">
                                African American
                              </IonSelectOption>
                              <IonSelectOption value="Hispanic American">
                                Hispanic American
                              </IonSelectOption>
                              <IonSelectOption value="Asian American">
                                Asian American
                              </IonSelectOption>
                              <IonSelectOption value="Native American">
                                Native American
                              </IonSelectOption>
                              <IonSelectOption value="Caucausian American">
                                Caucasian American
                              </IonSelectOption>
                              <IonSelectOption value="Other">
                                Other
                              </IonSelectOption>
                            </IonSelect>
                          )}
                        />
                      </IonItem>
                      {errors.ethnicity && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.ethnicity.message}
                        </p>
                      )}
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="4.5">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Preferred Trade</IonLabel>
                        <Controller
                          name="preferredTrade"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="text"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.preferredTrade && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.preferredTrade.message}
                        </p>
                      )}
                    </IonCol>
                    <IonCol size="12" sizeSm="2.5">
                      <IonItem mode="md">
                        <IonLabel position="stacked">Years experience</IonLabel>
                        <Controller
                          name="yearsOfExperience"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonInput
                              type="number"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                            />
                          )}
                        />
                      </IonItem>
                      {errors.yearsOfExperience && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.yearsOfExperience.message}
                        </p>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItemDivider
                        style={{
                          "--background": "none",
                          "--padding-start": "5px",
                          marginTop: "7px",
                          marginBottom: "5px",
                          marginLeft: "-5px",
                          fontWeight: "700",
                          fontSize: "16px",
                        }}
                      >
                        Select all that apply:
                      </IonItemDivider>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" sizeSm="4">
                      <IonItem mode="md" style={{ "--padding-start": "0px" }}>
                        <IonLabel
                          style={{ paddingLeft: "16px", fontSize: "12px" }}
                        >
                          High School Education
                        </IonLabel>
                        <Controller
                          name="highSchool"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonCheckbox
                              id="highSchool"
                              slot="end"
                              color="primary"
                              checked={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => {
                                onChange(e.detail.checked);
                              }}
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" sizeSm="4">
                      <IonItem mode="md" style={{ "--padding-start": "0px" }}>
                        <IonLabel
                          style={{ paddingLeft: "16px", fontSize: "12px" }}
                        >
                          College Education
                        </IonLabel>
                        <Controller
                          name="college"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonCheckbox
                              id="college"
                              slot="end"
                              color="primary"
                              checked={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => {
                                onChange(e.detail.checked);
                              }}
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" sizeSm="4">
                      <IonItem mode="md" style={{ "--padding-start": "0px" }}>
                        <IonLabel
                          style={{ paddingLeft: "16px", fontSize: "12px" }}
                        >
                          Member of the Armed Forces
                        </IonLabel>
                        <Controller
                          name="armedForces"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonCheckbox
                              id="armedForces"
                              slot="end"
                              color="primary"
                              checked={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => {
                                onChange(e.detail.checked);
                              }}
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItemDivider
                        style={{
                          "--background": "none",
                          "--padding-start": "5px",
                          marginTop: "7px",
                          marginBottom: "5px",
                          marginLeft: "-5px",
                          fontWeight: "700",
                          fontSize: "16px",
                        }}
                      >
                        Training Experience:
                      </IonItemDivider>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" sizeSm="12">
                      <IonItem mode="md">
                        <Controller
                          name="trainingExperience"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonTextarea
                              rows="3"
                              type="text"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                              placeholder="Provide a brief description of any occupational training, specialty training, or apprenticeship experience that you have had in the pasts."
                            />
                          )}
                        />
                      </IonItem>
                      {errors.trainingExperience && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.trainingExperience.message}
                        </p>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItemDivider
                        style={{
                          "--background": "none",
                          "--padding-start": "5px",
                          marginTop: "7px",
                          marginBottom: "5px",
                          marginLeft: "-5px",
                          fontWeight: "700",
                          fontSize: "16px",
                        }}
                      >
                        Skills &amp; Abilities:
                      </IonItemDivider>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" sizeSm="12">
                      <IonItem mode="md">
                        <Controller
                          name="skillsAndAbilities"
                          control={control}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <IonTextarea
                              rows="3"
                              type="text"
                              value={value}
                              onIonBlur={onBlur}
                              onIonChange={(e) => onChange(e.detail.value)}
                              placeholder="Summarize any construction related skills you have. Include any certifications, licenses, etc."
                            />
                          )}
                        />
                      </IonItem>
                      {errors.skillsAndAbilities && (
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "16px",
                            marginTop: "7px",
                            color: "var(--ion-color-danger)",
                          }}
                        >
                          {errors.skillsAndAbilities.message}
                        </p>
                      )}
                    </IonCol>
                  </IonRow>

                  <IonRow style={{ marginTop: "20px" }}>
                    <IonCol>
                      <IonButton
                        strong
                        fill="solid"
                        size="default"
                        expand="block"
                        color="primary"
                        onClick={handleSubmit((data) => handleSend(data))}
                      >
                        {formSending ? (
                          <IonSpinner
                            slot="end"
                            name="lines-small"
                            className="cip-spinner"
                          />
                        ) : !dashboardLoading ? (
                          "Update Profile"
                        ) : (
                          "Submit Profile"
                        )}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonList>
              </form>
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </>
  );
};

export default IndividualProfileForm;
