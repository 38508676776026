import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { IonButton, IonIcon } from "@ionic/react";
import { personCircle } from "ionicons/icons";

const SignupButton = ({ returnTo }) => {
  const { loginWithRedirect } = useAuth0();
  return (
    <>
      <IonButton
        onClick={() =>
          loginWithRedirect({
            screen_hint: "signup",
            appState: { returnTo: returnTo },
          })
        }
        size="small"
        color="secondary"
        fill="solid"
        strong
        style={{
          marginLeft: "20px",
          marginBottom: "85px",
          minHeight: "40px",
          minWidth: "140px",
        }}
      >
        <IonIcon slot="start" icon={personCircle} />
        Create Profile
      </IonButton>
    </>
  );
};

export default SignupButton;
