import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { IonButton, IonIcon } from "@ionic/react";
import SignupButton from "./SignUpButton";
import { logIn } from "ionicons/icons";

const LoginButton = ({ returnTo }) => {
  const { loginWithRedirect } = useAuth0();

  const appState = {
    returnTo: "text",
  };
  console.log(appState.returnTo);
  const handleLogin = () => {
    loginWithRedirect({ appState: { returnTo: returnTo } });
  };

  return (
    <>
      <IonButton
        onClick={handleLogin}
        fill="solid"
        size="small"
        color="secondary"
        strong
        style={{
          marginBottom: "85px",
          minHeight: "40px",
          minWidth: "130px",
        }}
      >
        <IonIcon slot="start" icon={logIn} />
        Sign In
      </IonButton>

      <SignupButton returnTo={returnTo} />
    </>
  );
};

export default LoginButton;
