/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonButtons,
  IonCol,
  IonHeader,
  IonRow,
  IonToolbar,
  IonButton,
  useIonModal,
  IonModal,
  IonCard,
  IonCardContent,
  IonIcon,
} from "@ionic/react";
import { useAuth0 } from "@auth0/auth0-react";
import DashboardLoading from "./WmbeSlbeLoading";
import ProfileTypeForm from "../../components/ProfileTypeForm";
import IconList from "../../components/IconList";
import IndividualProfileForm from "../../components/IndividualProfileForm";
import BusinessProfileForm from "../../components/BusinessProfileForm";
import axios from "axios";
import "./Workforce.scss";
import UserExportButton from "../../components/UserExportButton";
import { logOut } from "ionicons/icons";

const GMP1 = [
  {
    id: "u1",
    title: "To be announced",
    subTitle: "Scopes for GMP1 will be posted here when available",
    icon: "check",
    color: "secondary",
  },
];

const UPCOMING = [
  {
    id: "u1",
    title: "To be announced",
    subTitle: "Upcoming GMPs will be listed here when available",
    icon: "check",
    color: "secondary",
  },
];

const RESOURCES = [
  {
    id: "r1",
    title: "Ariel Business Group",
    subTitle: "City of Tampa WMBE/SLBE Certification",
    icon: "university",
    color: "secondary",
  },
  {
    id: "r2",
    title: "Ariel Business Group",
    subTitle: "Insurance and Bonding",
    icon: "chalkboard-teacher",
    color: "secondary",
  },
];

const EVENTS = [
  {
    id: "e1",
    title: "Virtual WMBE/SLBE Outreach Meeting",
    subTitle: "3/25/2021 - 9:00am-11:00am",
    link: "assets/img/Ariel-Flyer-2021-Final.jpg",
    icon: "calendar-day",
    color: "secondary",
  },
];

const ModalBody = ({ user, userToken, userMetadata, onSubmit, onLogout }) => (
  <>
    <IonHeader>
      <IonToolbar>
        <h3
          style={{
            marginBottom: "0px",
            color: "#fff",
            marginLeft: "15px",
            textAlign: "left",
          }}
        >
          Profile Setup
        </h3>

        <IonButtons slot="end">
          <IonButton
            onClick={onLogout}
            color="secondary"
            fill="solid"
            strong
            style={{
              marginRight: "10px",
              minHeight: "30px",
              minWidth: "100px",
              fontSize: "14px ",
              fontWeight: "600",
            }}
            size="small"
            mode="ios"
          >
            Sign Out
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <div className="modal-content-custom">
      <div className="modal-content-wrap">
        <ProfileTypeForm
          idToken={user.sub}
          accessToken={userToken}
          onUpdateProfile={onSubmit}
          userMetadata={userMetadata}
        />
      </div>
    </div>
  </>
);

const WmbeSlbeProtected = ({ showAccount, onToggleAccount }) => {
  const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [userMetadata, setUserMetadata] = useState({});
  const [appMetadata, setAppMetadata] = useState();
  const [userToken, setUserToken] = useState(null);
  const [formSending, setFormSending] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      getUserMetadata();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (appMetadata) {
      if (appMetadata.roles[0] === "admin") {
        setIsAdmin(true);
      }
    }
  }, [appMetadata]);

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      setUserToken(accessToken);

      const metadataResponse = await fetch(
        `https://login.tampawmi.com/api/v2/users/${user.sub}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const { user_metadata, app_metadata } = await metadataResponse.json();
      setAppMetadata(app_metadata);
      setUserMetadata(user_metadata);

      console.log("user-metadata", user_metadata);
      if (user_metadata.hasProfile === "false") {
        present({ cssClass: "auto-height", backdropDismiss: false });
        return;
      }
      if (user_metadata.hasProfile === "true") {
        if (
          user_metadata.accountType === "individual" &&
          user_metadata.name === undefined
        ) {
          onToggleAccount(true);
        } else if (
          user_metadata.accountType === "business" &&
          user_metadata.fein === undefined
        ) {
          onToggleAccount(true);
        } else {
          setDashboardLoading(false);
          onToggleAccount(false);
        }
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleProfileType = async (data) => {
    setFormSending(true);
    const token = await getAccessTokenSilently();
    var options = {
      method: "PATCH",
      url: `https://login.tampawmi.com/api/v2/users/${user.sub}`,
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      data: {
        user_metadata: data,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        getUserMetadata();
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {
        dismiss();
        setFormSending(false);
      });
  };

  const handleProfileSubmit = async (profile) => {
    setFormSending(true);
    const token = await getAccessTokenSilently();
    const individualData = {
      name: profile.data.name,
      phone: profile.data.phone,
      zipCode: profile.data.zipCode,
      gender: profile.data.gender,
      ethnicity: profile.data.ethnicity,
      educationMilitary:
        "Highschool: " +
        profile.data.highSchool +
        ", " +
        "College: " +
        profile.data.college +
        ", " +
        "Armed Forces: " +
        profile.data.armedForces,
      experience:
        "Preferred Trade: " +
        profile.data.preferredTrade +
        ", " +
        "Years of Experience: " +
        profile.data.yearsOfExperience +
        ", " +
        "Training Experience: " +
        profile.data.trainingExperience +
        ", " +
        "Skills & Abilities: " +
        profile.data.skillsAndAbilities,
    };
    // const wmbeSlbeData = {
    //   businessName: profile.data.legalName,
    //   fein: profile.data.fein,
    //   address:
    //     profile.data.address +
    //     " " +
    //     profile.data.city +
    //     ", " +
    //     profile.data.state +
    //     " " +
    //     profile.data.zipCode,
    //   phoneNumbers:
    //     "Primary: " +
    //     profile.data.phone +
    //     ", " +
    //     "Secondary: " +
    //     profile.data.phoneOther,
    //   website: profile.data.website,
    //   background:
    //     "Years In Business: " +
    //     profile.data.yearsInBusiness +
    //     ", " +
    //     "Number of Employees: " +
    //     profile.data.numberEmployees +
    //     ", " +
    //     "Number of Offices: " +
    //     profile.data.numberOffices,
    //   certifications:
    //     "Woman Owned: " +
    //     profile.data.womanOwned +
    //     ", " +
    //     "Minority Owned: " +
    //     profile.data.minorityOwned +
    //     ", " +
    //     "Small/Local: " +
    //     profile.data.smallLocal,
    //   owner:
    //     "Name: " +
    //     profile.data.ownerName +
    //     ", " +
    //     "Title: " +
    //     profile.data.ownerTitle +
    //     ", " +
    //     "Email: " +
    //     profile.data.ownerEmail +
    //     "Gender: " +
    //     profile.data.ownerGender +
    //     ", " +
    //     "Ethnicity: " +
    //     profile.data.ownerEthnicity,
    //   primaryContact:
    //     "Name: " +
    //     profile.data.primaryName +
    //     ", " +
    //     "Title: " +
    //     profile.data.primaryTitle +
    //     ", " +
    //     "Email: " +
    //     profile.data.primaryEmail,
    //   jobOpenings:
    //     "Permanent: " +
    //     profile.data.permanentJobOpenings +
    //     ", " +
    //     "Part-time/seasonal: " +
    //     profile.data.seasonalJobOpenings,
    //   jobDescriptions: profile.data.jobDescriptions,
    // };
    var options = {
      method: "PATCH",
      url: `https://login.tampawmi.com/api/v2/users/${user.sub}`,
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      data: {
        // user_metadata: profile.data,
        user_metadata:
          userMetadata.accountType === "individual"
            ? individualData
            : profile.data,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        getUserMetadata();
        if (!profile.hasCompletedProfile) {
          return axios.post(
            `https://us-central1-fy20-wmi-website.cloudfunctions.net/newProfile`,
            response.data
          );
        }
        return console.log("Just Update Profile");
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {
        dismiss();
        setFormSending(false);
      });
  };

  const handleFormLogout = () => {
    logout({
      returnTo: window.location.origin + window.location.pathname,
    });
  };

  const [present, dismiss] = useIonModal(ModalBody, {
    user,
    userToken,
    userMetadata,
    onSubmit: handleProfileType,
    onLogout: handleFormLogout,
  });

  return (
    <>
      {dashboardLoading && <DashboardLoading />}
      {!dashboardLoading && (
        <>
          <IonRow>
            <IonCol size="12" sizeLg="12" sizeXl="10" offsetXl="1">
              <IonRow>
                <IonCol
                  style={{
                    textAlign: "center",
                    marginBottom: "15px",
                  }}
                >
                  {isAdmin && <UserExportButton />}
                  <h2
                    className="home"
                    style={{
                      textAlign: "center",
                      marginBottom: "10px",
                      marginTop: "0px",
                    }}
                  >
                    Project <strong>Opportunities</strong>
                  </h2>
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "var(--ion-color-primary)",
                    }}
                  >
                    WMBE/SLBE Participation
                  </span>
                </IonCol>
              </IonRow>
              <IonRow style={{ marginBottom: "20px" }}>
                <IonCol size="12" sizeSm="12" sizeMd="6" sizeXl="6">
                  <IonCard className="card-margin">
                    <IonCardContent>
                      <IconList
                        title="GMP1"
                        icon="file-contract"
                        iconColor="primary"
                        data={GMP1}
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
                <IonCol size="12" sizeSm="12" sizeMd="6" sizeXl="6">
                  <IonCard className="card-margin">
                    <IonCardContent>
                      <IconList
                        title="Upcoming"
                        icon="clipboard-list"
                        iconColor="primary"
                        data={UPCOMING}
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <h2
                    className="home"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Helpful <strong>Information</strong>
                  </h2>
                </IonCol>
              </IonRow>
              <IonRow style={{ marginBottom: "0px" }}>
                <IonCol size="12" sizeSm="12" sizeMd="" sizeXl="6">
                  <IonCard className="card-margin">
                    <IonCardContent>
                      <IconList
                        title="Resources"
                        icon="briefcase"
                        iconColor="primary"
                        data={RESOURCES}
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>

                <IonCol size="12" sizeSm="12" sizeMd="6" sizeXl="6">
                  <IonCard className="card-margin">
                    <IonCardContent>
                      <IconList
                        title="Outreach Events"
                        icon="calendar-star"
                        iconColor="primary"
                        data={EVENTS}
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </>
      )}
      <IonModal
        isOpen={showAccount}
        onDidDismiss={() => onToggleAccount(false)}
        cssClass="profile-modal"
        mode="ios"
        backdropDismiss={false}
      >
        <IonHeader>
          <IonToolbar>
            <h3
              style={{
                marginBottom: "0px",
                color: "#fff",
                marginLeft: "15px",
                textAlign: "left",
              }}
            >
              {dashboardLoading ? "Profile Setup" : "Edit Profile"}
            </h3>
            <IonButtons slot="end">
              {dashboardLoading ? (
                <IonButton
                  onClick={handleFormLogout}
                  color="secondary"
                  fill="solid"
                  strong
                  style={{
                    marginRight: "10px",
                    minHeight: "30px",
                    minWidth: "100px",
                    fontSize: "14px ",
                    fontWeight: "600",
                  }}
                  size="small"
                  mode="ios"
                >
                  <IonIcon slot="start" icon={logOut} />
                  Sign Out
                </IonButton>
              ) : (
                <IonButton
                  strong
                  color="white"
                  onClick={() => onToggleAccount(false)}
                >
                  Cancel
                </IonButton>
              )}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {userMetadata.accountType === "individual" && (
          <IndividualProfileForm
            idToken={user.sub}
            accessToken={userToken}
            onSubmit={handleProfileSubmit}
            userMetadata={userMetadata}
            dashboardLoading={dashboardLoading}
            formSending={formSending}
          />
        )}
        {userMetadata.accountType === "business" && (
          <BusinessProfileForm
            idToken={user.sub}
            accessToken={userToken}
            onSubmit={handleProfileSubmit}
            userMetadata={userMetadata}
            dashboardLoading={dashboardLoading}
            formSending={formSending}
          />
        )}
      </IonModal>
    </>
  );
};

export default WmbeSlbeProtected;
