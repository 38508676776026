import { getConfData } from "../dataApi";

export const loadConfData = () => async (dispatch) => {
  dispatch(setLoading(true));
  await getConfData()
    .then((data) => {
      dispatch(setData(data));
      dispatch(setLoading(false));
    })
    .catch(async function () {
      console.log("no backup data!");
      dispatch(setLoading(false));
    });
};

export const setLoading = (isLoading) => ({
  type: "set-app-loading",
  isLoading,
});

export const setNetworkStatus = (networkStatus) => ({
  type: "set-network-status",
  networkStatus,
});

export const setData = (data) => ({
  type: "set-app-data",
  data,
});

export const updateFilteredProjectCategories = (filteredProjectCategories) => ({
  type: "update-filtered-project-categories",
  filteredProjectCategories,
});

export const updateFilteredAlertCategories = (filteredAlertCategories) => ({
  type: "update-filtered-alert-categories",
  filteredAlertCategories,
});

export const setProjectSearchText = (projectSearchText) => ({
  type: "set-project-search-text",
  projectSearchText,
});

export const setAlertSearchText = (alertSearchText) => ({
  type: "set-alert-search-text",
  alertSearchText,
});

export const setMenuEnabled = (menuEnabled) => ({
  type: "set-menu-enabled",
  menuEnabled,
});
