import React, { useState } from "react";
import {
  IonLabel,
  IonItem,
  IonList,
  IonSkeletonText,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonModal,
  IonSpinner,
  useIonToast,
} from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import "./JobList.scss";
import { logEvent } from "firebase/analytics";
import { analytics } from "..";

const JobList = (props) => {
  const {
    title,
    icon,
    iconColor,
    jobs,
    detailText,
    loading,
    userMetadata,
    user,
  } = props;
  const [showJobOpening, setShowJobOpening] = useState(false);
  const [jobContent, setJobContent] = useState({
    title: "",
    purpose: "",
    duties: "",
    requirements: "",
  });
  const handleOpenJobOpening = (job) => {
    setJobContent({
      title: job.title,
      purpose: job.purpose,
      duties: job.duties,
      requirements: job.requirements,
    });
    setShowJobOpening(true);
  };

  const logJobView = () => {
    logEvent(analytics, "view_job_opening", { job: jobContent.title });
  };

  const [present, dismiss] = useIonToast();
  const [isSending, setIsSending] = useState(false);
  const handleSend = async (jobData) => {
    logEvent(analytics, "goal_completion", { job_title: jobData.title });
    const data = {
      jobData: jobData,
      userMetadata: userMetadata,
      user: user,
    };

    setIsSending(true);
    axios
      .post(
        "https://us-central1-fy20-wmi-website.cloudfunctions.net/jobInterest",
        data
      )
      .then(function (response) {
        if (response.status === 200) {
          present({
            duration: 3500,
            buttons: [{ text: "Hide", handler: () => dismiss() }],
            animated: true,
            position: "bottom",
            color: "dark",
            message:
              "A workforce representative has been notified and will be in touch with you shortly.",
          });
        } else {
          present({
            duration: 3500,
            buttons: [{ text: "Hide", handler: () => dismiss() }],
            animated: true,
            position: "bottom",
            color: "dark",
            message: "Your job interest could not be sent. Please try again.",
          });
        }
      })
      .catch(function (error) {
        present({
          duration: 2800,
          buttons: [{ text: "Hide", handler: () => dismiss() }],
          animated: true,
          position: "bottom",
          color: "dark",
          message: "Job opening interest could not be sent. Please try again.",
        });
      })
      .finally(() => {
        setIsSending(false);
        setShowJobOpening(false);
      });
  };

  return (
    <>
      <div className="hb-list">
        <div style={{ display: "block", textAlign: "center" }}>
          {icon === "westra" ? (
            <>
              {!loading ? (
                <img
                  src="assets/img/westra-logo-outlined.png"
                  alt="Westra Contruction logo"
                  width="400"
                  height="400"
                  style={{
                    maxWidth: "78px",
                    height: "auto",
                    marginBottom: "14px",
                  }}
                />
              ) : (
                <IonSkeletonText
                  animated
                  style={{
                    width: "78px",
                    height: "76px",
                    margin: "0 auto",
                    marginBottom: "14px",
                    borderRadius: "10px",
                  }}
                />
              )}
            </>
          ) : (
            <>
              {!loading ? (
                <FontAwesomeIcon
                  icon={["fad", `${icon}`]}
                  style={{
                    fontSize: "55px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                  color={`var(--ion-color-${
                    iconColor ? iconColor : "secondary"
                  })`}
                />
              ) : (
                <IonSkeletonText
                  animated
                  style={{
                    width: "78px",
                    height: "76px",
                    margin: "0 auto",
                    marginBottom: "14px",
                    borderRadius: "10px",
                  }}
                />
              )}
            </>
          )}
        </div>
        <h3
          style={{
            textAlign: "center",
            fontSize: "28px",
            marginBottom: "10px",
            fontWeight: "500",
            color: "#000",
          }}
        >
          {!loading ? (
            title
          ) : (
            <IonSkeletonText
              animated
              style={{
                width: "300px",
                height: "30px",
                margin: "0 auto",
              }}
            />
          )}
        </h3>
        <div className="dotted-divider">
          <span></span>
        </div>

        <IonList className="resource-list" style={{ background: "none" }}>
          {jobs.map((job) => (
            <IonItem
              lines="none"
              className="list-item"
              key={job.id}
              button
              detail={true}
              target="_blank"
              onClick={() => handleOpenJobOpening(job)}
            >
              <div slot="start">
                {!loading ? (
                  <FontAwesomeIcon
                    color={`var(--ion-color-${
                      job.color ? job.color : "secondary"
                    })`}
                    icon={["fas", `${job.icon}`]}
                    style={{
                      fontSize: "30px",
                      minWidth: "30px",
                    }}
                  />
                ) : (
                  <IonSkeletonText
                    animated
                    style={{
                      width: "30px",
                      height: "30px",
                      margin: "0 auto",
                    }}
                  />
                )}
              </div>
              <IonLabel className="hb-label">
                <span>
                  {!loading ? (
                    job.title
                  ) : (
                    <IonSkeletonText
                      animated
                      style={{
                        width: "170px",
                        height: "1.1rem",
                        borderRadius: "0px",
                      }}
                    />
                  )}
                </span>

                {job.subTitle && (
                  <h3>
                    {!loading ? (
                      job.subTitle
                    ) : (
                      <IonSkeletonText
                        animated
                        style={{
                          width: "100px",
                          height: "14px",
                          borderRadius: "0px",
                        }}
                      />
                    )}
                  </h3>
                )}
              </IonLabel>
              <span slot="end" className="detailText">
                {detailText}
              </span>
            </IonItem>
          ))}
        </IonList>
      </div>

      <IonModal
        isOpen={showJobOpening}
        onDidDismiss={() => setShowJobOpening(false)}
        cssClass="auto-height"
        mode="ios"
        backdropDismiss={false}
        onDidPresent={logJobView}
      >
        <IonHeader>
          <IonToolbar>
            <h3
              style={{
                marginBottom: "0px",
                color: "#fff",
                marginLeft: "15px",
                textAlign: "left",
              }}
            >
              {jobContent.title}
            </h3>
            <IonButtons slot="end">
              <IonButton
                strong
                color="white"
                onClick={() => setShowJobOpening(false)}
              >
                <b> Close</b>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="modal-content-custom">
          <div className="modal-content-wrap">
            {/* <h2 style={{ marginBottom: "15px" }}> {jobContent.title}</h2> */}
            <div>
              <h5 className="job-list" style={{ marginTop: "0px" }}>
                Primary Purpose
              </h5>

              <p className="job-list">{jobContent.purpose}</p>
            </div>
            <h5 className="job-list">Typical Duties</h5>
            <p className="job-list">{jobContent.duties}</p>
            <h5 className="job-list">Requirements</h5>
            <p className="job-list">{jobContent.requirements}</p>
          </div>
          <IonButton
            expand="block"
            strong
            fill="solid"
            color="primary"
            inlist
            size="default"
            hidden={userMetadata.accountType === "business"}
            onClick={() => handleSend(jobContent)}
            style={{ marginTop: "20px" }}
          >
            {isSending ? (
              <IonSpinner
                slot="end"
                name="lines-small"
                className="cip-spinner"
                color="white"
              />
            ) : (
              <>I'm Interested In This Job</>
            )}
          </IonButton>
        </div>
      </IonModal>
    </>
  );
};

export default JobList;
