import React, { useState } from "react";
import {
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
} from "@ionic/react";
import { logEvent } from "firebase/analytics";
import ContactForm from "../ContactForm";
import { analytics } from "../..";

const AccessibilityModal = ({ submitForm }) => {
  const [accessibilityModalShow, setAccessibilityModalShow] = useState(false);

  const logAccesibilityModal = () => {
    logEvent(analytics, "view_accessibility");
  };

  return (
    <>
      <IonButton
        fill="clear"
        size="small"
        color="dark"
        onClick={() => setAccessibilityModalShow(true)}
      >
        Accessibility
      </IonButton>
      <IonModal
        isOpen={accessibilityModalShow}
        onDidDismiss={() => setAccessibilityModalShow(false)}
        cssClass="auto-height profile-form"
        mode="ios"
        backdropDismiss={false}
        onDidPresent={logAccesibilityModal}
      >
        <IonHeader>
          <IonToolbar>
            <h3
              style={{
                marginBottom: "0px",

                color: "#fff",
                marginLeft: "15px",
              }}
            >
              Accessibility
            </h3>

            <IonButtons slot="end">
              <IonButton
                color="white"
                onClick={() => setAccessibilityModalShow(false)}
              >
                <strong>Close</strong>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <div className="modal-content-custom">
          <div className="modal-content-wrap">
            <p>
              The developers of this website strive to ensure that its services
              are accessible to people with disabilities, with the strong belief
              that every person has the right to live with dignity, equality,
              comfort and independence both in life, and on the internet.
            </p>
            <br />
            <p>
              If you are unable to use any aspect of this website, PDF files, or
              other attachments, we can provide you with prompt assistance.
              Please let us know by filling out the form below or by emailing{" "}
              <a href="mailto:ada-support@valerin-group.com">
                ada-support@valerin-group.com
              </a>
            </p>
            <br />
            <ContactForm functionUrl="https://us-central1-fy20-wmi-website.cloudfunctions.net/accessibilityForm" />
          </div>
        </div>
      </IonModal>
    </>
  );
};

export default AccessibilityModal;

// <IonList style={{ marginTop: "20px" }}>
// <IonRow>
//   <IonCol>
//     <IonItem mode="md">
//       <IonLabel position="floating">First Name</IonLabel>
//       <IonInput
//         type="text"
//         name="firstName"
//         value={values.firstName}
//         onIonChange={handleChange}
//         onIonBlur={handleBlur}
//       ></IonInput>
//     </IonItem>
//     {errors.firstName && (
//       <p
//         style={{
//           fontSize: "13px",
//           marginLeft: "16px",
//           color: "var(--ion-color-danger)",
//         }}
//       >
//         {errors.firstName}
//       </p>
//     )}
//   </IonCol>
//   <IonCol>
//     <IonItem mode="md">
//       <IonLabel position="floating">Last Name</IonLabel>
//       <IonInput
//         type="text"
//         name="lastName"
//         value={values.lastName}
//         onIonChange={handleChange}
//         onIonBlur={handleBlur}
//       ></IonInput>
//     </IonItem>
//     {/* {errors.lastName && (
//       <p
//         style={{
//           fontSize: "13px",
//           marginLeft: "16px",
//           color: "var(--ion-color-danger)",
//         }}
//       >
//         {errors.lastName}
//       </p>
//     )} */}
//   </IonCol>
// </IonRow>
// <IonRow>
//   <IonCol>
//     <IonItem mode="md">
//       <IonLabel position="floating">Phone Number</IonLabel>
//       <IonInput
//         name="phoneNumber"
//         type="tel"
//         value={values.phoneNumber}
//         onIonChange={handleChange}
//         onIonBlur={handleBlur}
//       ></IonInput>
//     </IonItem>
//     {/* {errors.phoneNumber && (
//       <p
//         style={{
//           fontSize: "13px",
//           marginLeft: "16px",
//           color: "var(--ion-color-danger)",
//         }}
//       >
//         {errors.phoneNumber}
//       </p>
//     )} */}
//   </IonCol>
//   <IonCol>
//     <IonItem mode="md">
//       <IonLabel position="floating">Email Address</IonLabel>
//       <IonInput
//         name="email"
//         type="email"
//         value={values.email}
//         onIonChange={handleChange}
//         onIonBlur={handleBlur}
//       ></IonInput>
//     </IonItem>
//     {/* {errors.email && (
//       <p
//         style={{
//           fontSize: "13px",
//           marginLeft: "16px",
//           color: "var(--ion-color-danger)",
//         }}
//       >
//         {errors.email}
//       </p>
//     )} */}
//   </IonCol>
// </IonRow>
// <IonRow>
//   <IonCol>
//     <IonItem mode="md">
//       <IonLabel position="floating">Message</IonLabel>
//       <IonTextarea
//         type="text"
//         name="message"
//         value={values.message}
//         onIonChange={handleChange}
//         onIonBlur={handleBlur}
//       ></IonTextarea>
//     </IonItem>
//     {/* {errors.message && (
//       <p
//         style={{
//           fontSize: "13px",
//           marginLeft: "16px",
//           color: "var(--ion-color-danger)",
//         }}
//       >
//         {errors.message}
//       </p>
//     )} */}

//     <br />

//     <span className="disclaimer">
//       Disclaimer: Requested information is submitted
//       voluntarily and not required. Information collected,
//       including internet protocol (IP) addresses, is not
//       cross-referenced to individual users, redistributed or
//       sold to intermediaries or third parties.
//     </span>
//   </IonCol>
// </IonRow>
// </IonList>
