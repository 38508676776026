import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IonIcon, IonPopover, IonButton, IonList } from '@ionic/react';
import { menu } from 'ionicons/icons';

import ProjectList from './ProjectList';

const ProjectListPopover = () => {
	const [popoverState, setShowPopover] = useState({
		showPopover: false,
		event: undefined,
	});
	const handleSelectProject = (e) => {
		e.persist();
		setShowPopover({ showPopover: false, event: e });
	};
	return (
		<>
			<IonPopover
				mode='ios'
				cssClass='project-list-popover'
				event={popoverState.event}
				isOpen={popoverState.showPopover}
				onDidDismiss={() => setShowPopover({ showPopover: false })}>
				<IonList className='project-list'>
					<ProjectList onSelectProject={handleSelectProject} />
				</IonList>
			</IonPopover>
			<IonButton
				mode='ios'
				color='secondary'
				shape='round'
				strong
				size='default'
				onClick={(e) => {
					e.persist();
					setShowPopover({ showPopover: true, event: e });
				}}
				className='ion-hide-lg-up'
				style={{ margin: '16px auto 0px' }}>
				<IonIcon slot='end' icon={menu} />
				Project Locations
			</IonButton>
		</>
	);
};

export default ProjectListPopover;
