import React from "react";
import {
  // isPlatform,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
} from "@ionic/react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Zoom } from "swiper";

import { connect } from "../../data/connect";

import "swiper/swiper-bundle.min.css";
import "./LightboxModal.scss";

SwiperCore.use([Pagination, Zoom]);

const LightboxModal = ({ images, initialSlide, onDismissModal }) => {
  const getAttachmentTitle = (title) => {
    return title.replace(/\.[^/.]+$/, "");
  };

  return (
    <>
      <IonHeader className="lightbox-header">
        <IonToolbar>
          <IonTitle color="white">Current Activities</IonTitle>
          <IonButtons slot="end">
            <IonButton color="white" onClick={onDismissModal} strong>
              <strong>Close</strong>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true} slot="fixed" force-overscroll={false}>
        <Swiper
          initialSlide={initialSlide}
          zoom={{
            maxRatio: 3,
            containerClass: "swiper-zoom-container",
          }}
          pagination={{ clickable: true }}
          style={{ height: "100%", width: "100%", background: "#000" }}
          width={window.innerWidth}
          spaceBetween={20}
          slidesPerView={1}
        >
          {images.map((image) => (
            <SwiperSlide key={image.url}>
              <div className="swiper-zoom-container">
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={`${image.url}`} />
              </div>
              <div className="image-caption">
                <p>{getAttachmentTitle(image.name)}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </IonContent>
    </>
  );
};

export default connect({
  component: LightboxModal,
});
