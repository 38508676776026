import React, { useContext } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { NavContext } from "@ionic/react";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = "login.tampawmi.com";
  const clientId = "HWgI4N7h3yB1oB4G1zTj0BCsjywpUwhe";
  const { navigate } = useContext(NavContext);

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname, "root", "replace");
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      issuer="login.tampawmi.com"
      redirectUri={window.location.origin}
      audience={"https://city-of-tampa.us.auth0.com/api/v2/"}
      onRedirectCallback={onRedirectCallback}
      scope={
        "openid offline_access email picture profile read:users read:current_user read:current_user_metadata update:current_user_metadata"
      }
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
