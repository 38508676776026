/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IonButton, IonSpinner, IonIcon } from '@ionic/react';
import axios from 'axios';
import { server } from 'ionicons/icons';
import { logEvent } from 'firebase/analytics';
import { analytics } from '..';

const UserExportButton = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [downloadURL, setDownloadURL] = useState();

	const handleDatabaseExport = async () => {
		setIsLoading(true);
		axios
			.post(
				'https://us-central1-fy20-wmi-website.cloudfunctions.net/downloadUserDatabase'
			)
			.then(function (response) {
				setDownloadURL(response.data.location);
				setIsLoading(false);
			})
			.catch(function (error) {
				console.error(error);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (downloadURL) {
			function downloadURI(uri, name) {
				var link = document.createElement('a');
				link.download = name;
				link.href = uri;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			downloadURI(downloadURL, 'User Database');
			logEvent(analytics, 'download_user_database');
		}
	}, [downloadURL]);

	return (
		<>
			<IonButton
				color='primary'
				onClick={handleDatabaseExport}
				style={{ marginBottom: '40px' }}>
				Download User Database
				{isLoading ? (
					<IonSpinner
						slot='end'
						name='lines-small'
						className='cip-spinner'
					/>
				) : (
					<IonIcon icon={server} slot='end'></IonIcon>
				)}
			</IonButton>
		</>
	);
};

export default UserExportButton;
