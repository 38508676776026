import React, { useState, useEffect } from 'react';
import {
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonCardSubtitle,
	IonList,
	IonItem,
	IonLabel,
	IonModal,
} from '@ionic/react';
import { connect } from '../../../data/connect';
import * as selectors from '../../../data/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SocialSharePopover from '../../../components/SocialSharePopover';
import LightboxModal from '../../../components/LightboxModal/LightboxModal';
import ImageThumbmails from '../../../components/ImageThumbails';
import './ProjectCard.scss';
import '../../../components/LightboxModal/LightboxModal.scss';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../..';

const dateFormatter = (timestamp) => {
	var date = new Date(timestamp);
	var day = [];
	day[0] = 'Sunday';
	day[1] = 'Monday';
	day[2] = 'Tuesday';
	day[3] = 'Wednesday';
	day[4] = 'Thursday';
	day[5] = 'Friday';
	day[6] = 'Saturday';
	var month = [];
	month[0] = 'January';
	month[1] = 'February';
	month[2] = 'March';
	month[3] = 'April';
	month[4] = 'May';
	month[5] = 'June';
	month[6] = 'July';
	month[7] = 'August';
	month[8] = 'September';
	month[9] = 'October';
	month[10] = 'November';
	month[11] = 'December';
	var m = month[date.getMonth()];
	var year = date.getFullYear();
	return m + ` ` + year;
};

const ProjectCard = ({ locations, projectSlug, locationAttachments }) => {
	const project = locations.find(
		(p) =>
			p.attributes.projname.replace(/\s+/g, '-').toLowerCase() ===
			projectSlug
	);
	const selectedProject = project.attributes;
	// Attachments
	const [images, setImages] = useState();

	const [documents, setDocuments] = useState();
	useEffect(() => {
		const attachments = locationAttachments.find(
			(a) => a.parentObjectId === selectedProject.OBJECTID
		);
		if (attachments) {
			const images = attachments.attachmentInfos.filter((a) =>
				a.contentType.includes('image')
			);
			const documents = attachments.attachmentInfos.filter((a) =>
				a.contentType.includes('pdf')
			);

			if (images.length > 0) {
				setImages(images);
			}
			setDocuments(documents);
		}
	}, [locationAttachments, selectedProject]);
	// Images Lightbox
	const [showLightboxModal, setShowLightboxModal] = useState(false);
	const [lightboxContent, setLightboxContent] = useState();
	const [initialSlide, setInitialSlide] = useState(0);
	const openLightbox = (index, images) => {
		setLightboxContent(images);
		setInitialSlide(index);
		setShowLightboxModal(true);
	};
	// Text conversions
	const createParagraphs = (text) => {
		return {
			__html: (text || '').replace(/:break/g, '<br /><br />'),
		};
	};
	const getAttachmentTitle = (title) => {
		return title.replace(/\.[^/.]+$/, '');
	};

	const logDocumentClick = (d) => {
		logEvent(analytics, 'view_project_document', {
			project: selectedProject.title,
			title: getAttachmentTitle(d.name),
			link: d.url,
		});
	};

	return (
		<>
			<IonCardHeader>
				<IonCardSubtitle
					color={
						selectedProject.status === 'inConstruction'
							? `inconstruction`
							: selectedProject.status === 'inDesign'
							? `indesign`
							: `completed`
					}>
					{selectedProject.status === 'inConstruction'
						? `In Construction`
						: selectedProject.status === 'inDesign'
						? `In Design`
						: `Completed`}
				</IonCardSubtitle>
				<IonCardTitle>{selectedProject.title}</IonCardTitle>
				<SocialSharePopover projectName={selectedProject.title} />
			</IonCardHeader>
			<IonCardContent>
				<div
					className={
						selectedProject.status === 'inConstruction'
							? `project-location-date-wrap-inconstruction `
							: selectedProject.status === 'inDesign'
							? `project-location-date-wrap-indesign`
							: `project-location-date-wrap-completed`
					}>
					{selectedProject.location && (
						<div className='project-location'>
							<p>
								<span className='project-location-title'>
									Location:
								</span>{' '}
								<span>{selectedProject.location}</span>
							</p>
						</div>
					)}
					<div className='project-dates-container'>
						{selectedProject.startDate ? (
							<div className='project-end-date'>
								<p>
									<span className='project-end-date-title'>
										Start Date:
									</span>{' '}
									<span>
										{dateFormatter(
											selectedProject.startDate
										)}
									</span>
								</p>
							</div>
						) : (
							<div className='project-end-date'>
								<p>
									<span className='project-end-date-title'>
										Start Date:
									</span>{' '}
									<span>TBD</span>
								</p>
							</div>
						)}
						{selectedProject.endDate ? (
							<div className='project-end-date'>
								<p>
									<span className='project-end-date-title'>
										End Date:
									</span>{' '}
									<span>
										{dateFormatter(selectedProject.endDate)}
									</span>
								</p>
							</div>
						) : (
							<div className='project-end-date'>
								<p>
									<span className='project-end-date-title'>
										End Date:
									</span>{' '}
									<span>TBD</span>
								</p>
							</div>
						)}
					</div>
				</div>
				<div className='project-body-text'>
					{selectedProject.projdesc && <h2>Description</h2>}

					{selectedProject.projdesc && (
						<p
							dangerouslySetInnerHTML={createParagraphs(
								selectedProject.projdesc
							)}
						/>
					)}
					{selectedProject.currentActivities && (
						<h2>Current Activities</h2>
					)}

					<div className='project-body about-body-text'>
						{selectedProject.currentActivities && (
							<p
								className='ion-margin-bottom'
								dangerouslySetInnerHTML={createParagraphs(
									selectedProject.currentActivities
								)}
							/>
						)}
					</div>
				</div>

				{images && (
					<>
						<div className='block project-images'>
							{images.map((image, index) => (
								<ImageThumbmails
									onClick={() => openLightbox(index, images)}
									src={image.url}
									key={index}
									alt='Image'
								/>
							))}
						</div>
						<IonModal
							isOpen={showLightboxModal}
							onDidDismiss={() => setShowLightboxModal(false)}
							swipeToClose={true}
							id='lightboxModal'
							backdropDismiss={false}
							mode='md'>
							<LightboxModal
								onDismissModal={() =>
									setShowLightboxModal(false)
								}
								initialSlide={initialSlide}
								images={lightboxContent}
							/>
						</IonModal>
					</>
				)}

				{documents && (
					<>
						{documents.length > 0 && (
							<div className='project-body-text'>
								<h2>Documents</h2>
							</div>
						)}
						<IonList
							class='project-list'
							style={{
								maxWidth: 'max-content',
								marginTop: '-5px',
							}}>
							{documents.map((document) => (
								<IonItem
									lines='none'
									key={document.id}
									routerDirection='none'
									href={document.url}
									download={document.title}
									detail={true}
									target='_blank'
									onClick={() => logDocumentClick(document)}>
									<div
										slot='start'
										style={{ marginInlineEnd: '0px' }}>
										<FontAwesomeIcon
											color='var(--ion-color-primary)'
											icon={['fas', `file-pdf`]}
											style={{
												fontSize: '22px',
												minWidth: '30px',
												marginTop: '4px',
											}}
										/>
									</div>
									<IonLabel>
										<h2
											style={{
												fontSize: '16px',
												marginRight: '10px',
												marginTop: '0px',
											}}>
											{getAttachmentTitle(document.name)}
										</h2>
									</IonLabel>
								</IonItem>
							))}
						</IonList>
					</>
				)}
			</IonCardContent>
		</>
	);
};

export default connect({
	mapStateToProps: (state) => ({
		locations: state.data.locations,
		locationAttachments: selectors.getLocationAttachments(state),
	}),
	component: React.memo(ProjectCard),
});
